import React, { useEffect, useState } from 'react';
import Decode from 'jwt-decode';
import Switch from "react-switch";

// IMPORT DEPENDENCIES
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAppSettingsService, lokantaSwitch, cicekSwitch, hizmetBedeliMK,
    hizmetBedeliAC, kmBasiMK, mK_1_5, mK_6_11, mK_12_18, kmBasiAC,
    aC_1_5, aC_6_11, aC_12_18, ApiKey, updateAppSettingsService,
    destekNumara, getYuzde, topluDagitim
} from '../../actions/admin/admin';

//IMPORT COMPONENT
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Loader from '../../components/loader/loader';


const Settings = () => {


    const Dispatch = useDispatch();

    useEffect(() => {
        Dispatch(getAppSettingsService(token));
    }, []);


    const loading = useSelector(state => state.AdminReducer.loading);
    const loadingsettings = useSelector(state => state.AdminReducer.loadingsettings);
    const appsettingslist = useSelector(state => state.AdminReducer.appsettingslist);
    const token = useSelector(state => state.AuthReducer.token);
    const hizmetbedeliMK = useSelector(state => state.AdminReducer.hizmetbedeliMK);
    const hizmetbedeliAC = useSelector(state => state.AdminReducer.hizmetbedeliAC);
    const kmbasiMk = useSelector(state => state.AdminReducer.kmbasiMK);
    const kmbasiAC = useSelector(state => state.AdminReducer.kmbasiAC);
    const mk_1_5 = useSelector(state => state.AdminReducer.mk_1_5);
    const mk_6_11 = useSelector(state => state.AdminReducer.mk_6_11);
    const mk_12_18 = useSelector(state => state.AdminReducer.mk_12_18);
    const ac_1_5 = useSelector(state => state.AdminReducer.ac_1_5);
    const ac_6_11 = useSelector(state => state.AdminReducer.ac_6_11);
    const ac_12_18 = useSelector(state => state.AdminReducer.ac_12_18);
    const lokantacheck = useSelector(state => state.AdminReducer.lokantacheck);
    const cicekcheck = useSelector(state => state.AdminReducer.cicekcheck);
    const apikey = useSelector(state => state.AdminReducer.apikey);
    const desteknumara = useSelector(state => state.AdminReducer.desteknumara);
    const yuzde = useSelector(state => state.AdminReducer.yuzde);
    const updateappsettingstatus = useSelector(state => state.AdminReducer.updateappsettingstatus);
    const topludagitim = useSelector(state => state.AdminReducer.topludagitim);


    const [errorMK, setErrorMK] = useState(false);
    const [errorAC, setErrorAC] = useState(false);
    const [errorBI, setErrorBI] = useState(false);
    const [errorTO, setErrorTO] = useState(false);


    const lokantaSwicth = () => {
        Dispatch(lokantaSwitch(lokantacheck))
    }

    const handleTopluDagitim = (e) => {
        Dispatch(topluDagitim(e.target.value))
        setErrorTO(false)
    }

    const cicekSwicth = () => {
        Dispatch(cicekSwitch(cicekcheck))
    }

    const handleHizmetBedeliMK = (e) => {
        setErrorMK(false)
        Dispatch(hizmetBedeliMK(e.target.value))
    }

    const handleHizmetBedeliAC = (e) => {
        setErrorAC(false)
        Dispatch(hizmetBedeliAC(e.target.value))
    }

    const handleKmBasiMK = (e) => {
        setErrorMK(false)
        Dispatch(kmBasiMK(e.target.value))
    }

    const handleMK_1_5 = (e) => {
        setErrorMK(false)
        Dispatch(mK_1_5(e.target.value))
    }

    const handleMK_6_11 = (e) => {
        setErrorMK(false)
        Dispatch(mK_6_11(e.target.value))
    }

    const handleMK_12_18 = (e) => {
        setErrorMK(false)
        Dispatch(mK_12_18(e.target.value))
    }

    const handleKmBasiAc = (e) => {
        setErrorAC(false)
        Dispatch(kmBasiAC(e.target.value))
    }

    const handleAC_6_11 = (e) => {
        setErrorAC(false)
        Dispatch(aC_6_11(e.target.value))
    }

    const handleAC_1_5 = (e) => {
        setErrorAC(false)
        Dispatch(aC_1_5(e.target.value))
    }

    const handleAC_12_18 = (e) => {
        setErrorAC(false)
        Dispatch(aC_12_18(e.target.value))
    }

    const handleApiKey = (e) => {
        setErrorBI(false)
        Dispatch(ApiKey(e.target.value))
    }

    const handleDestekNumara = (e) => {
        setErrorBI(false)
        Dispatch(destekNumara(e.target.value))
    }

    const handleYuzde = (e) => {
        setErrorBI(false)
        Dispatch(getYuzde(e.target.value))
    }

    const handleUpdateAppSettings = () => {
        if (hizmetbedeliMK === "" || hizmetbedeliMK.trim() === "" || Number(hizmetbedeliMK) <= 0 || kmbasiMk === "" ||
            kmbasiMk.trim() === "" || Number(kmbasiMk) <= 0 || mk_1_5 === "" || mk_1_5.trim() === "" || Number(mk_1_5) <= 0 || mk_6_11 === ""
            || mk_6_11.trim() === "" || Number(mk_6_11) <= 0 || mk_12_18 === "" || mk_12_18.trim() === "" || Number(mk_12_18) <= 0) {
            setErrorMK(true)
        }
        else if (hizmetbedeliAC === "" || hizmetbedeliAC.trim() === "" || Number(hizmetbedeliAC) <= 0 || kmbasiAC === "" ||
            kmbasiAC.trim() === "" || Number(kmbasiAC) <= 0 || ac_1_5 === "" || ac_1_5.trim() === "" || Number(ac_1_5) <= 0 || ac_6_11 === ""
            || ac_6_11.trim() === "" || Number(ac_6_11) <= 0 || ac_12_18 === "" || ac_12_18.trim() === "" || Number(ac_12_18) <= 0  ) {
            setErrorAC(true)
        }
        else if(topludagitim ==="" || topludagitim.trim() === ""){
            setErrorTO(true)
        }
        else if (apikey === "" || apikey.trim() === "" || desteknumara ==="" || desteknumara.trim() ===""  || yuzde ==="" || yuzde.trim() ==="" || Number(yuzde)>100) {
            setErrorBI(true)
        }
        else{
            Dispatch(updateAppSettingsService(hizmetbedeliMK,kmbasiMk,mk_1_5,mk_6_11,mk_12_18,hizmetbedeliAC,kmbasiAC,ac_1_5,ac_6_11,ac_12_18,apikey,lokantacheck,cicekcheck, desteknumara, yuzde, topludagitim, token))
        }
    }

    let PageRedirect = null
    if (updateappsettingstatus) {
        PageRedirect = <Redirect to="/" />
    }

    return (
        <div>
            <Header />
            <div class="wrapper">
                <div class="container-fluid">

                    {
                        loadingsettings ?
                            <div class="col-sm-10">
                                <Loader />
                            </div> :

                            <>
                                {PageRedirect}
                                <div class="row">
                                    <div class="col-12">
                                        <div class="page-title-box">
                                            <div class="page-title-right">
                                                <ol class="breadcrumb m-0">
                                                    <li class="breadcrumb-item"><a href="javascript: void(0);">Aninda Bi'Kurye</a></li>
                                                    <li class="breadcrumb-item"><a href="javascript: void(0);">Uyugulama Ayarlama</a></li>
                                                    <li class="breadcrumb-item active">Ayarlamak</li>
                                                </ol>
                                            </div>
                                            <h4 class="page-title"></h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="card-box">
                                            <h4 class="m-t-0 header-title">Araç</h4>
                                            <p class="text-muted mb-4 font-14">
                                            </p>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="p-2">

                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">Hizmet Bedeli</label>
                                                            <div class="col-sm-10">
                                                                <input type="number" id="simpleinput" value={hizmetbedeliMK} onChange={handleHizmetBedeliMK} class="form-control" placeholder="Hizmet Bedeli (Motor Kurye)" />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">Km Basi</label>
                                                            <div class="col-sm-10">
                                                                <input type="number" id="simpleinput" value={kmbasiMk} onChange={handleKmBasiMK} class="form-control" placeholder="Km Basi (Motor Kurye)" />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">1 - 5 (KM)</label>
                                                            <div class="col-sm-10">
                                                                <input type="number" id="simpleinput" value={mk_1_5} onChange={handleMK_1_5} class="form-control" placeholder='Km Fiyat (Motor Kurye)' />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">6 - 11 (KM)</label>
                                                            <div class="col-sm-10">
                                                                <input type="number" id="simpleinput" value={mk_6_11} onChange={handleMK_6_11} class="form-control" placeholder='Km Fiyat (Motor Kurye)' />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">12 - 18 (KM)</label>
                                                            <div class="col-sm-10">
                                                                <input type="number"  id="simpleinput" value={mk_12_18} onChange={handleMK_12_18} class="form-control" placeholder='Km Fiyat (Motor Kurye)' />
                                                            </div>
                                                        </div>


                                                        {
                                                            errorMK ?
                                                                <div class="form-group row">
                                                                    <label class="col-sm-2  col-form-label" for="simpleinput"></label>
                                                                    <div class="col-sm-10">
                                                                        <p style={{ color: 'red' }}>Bilgilerinizi kontrol edin lütfen!</p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput"></label>
                                                            {loading ? <div class="col-sm-10">
                                                                <Loader />
                                                            </div>
                                                                :
                                                                null
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="card-box">
                                            <h4 class="m-t-0 header-title">Toplu dagitim</h4>
                                            <p class="text-muted mb-4 font-14">
                                            </p>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="p-2">

                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">Fiyat</label>
                                                            <div class="col-sm-10">
                                                                <input type="number" id="simpleinput" value={topludagitim} onChange={handleTopluDagitim} class="form-control" placeholder="Hizmet Bedeli (Motor Kurye)" />
                                                            </div>
                                                        </div>

                                                        {
                                                            errorTO ?
                                                                <div class="form-group row">
                                                                    <label class="col-sm-2  col-form-label" for="simpleinput"></label>
                                                                    <div class="col-sm-10">
                                                                        <p style={{ color: 'red' }}>Bilgilerinizi kontrol edin lütfen!</p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput"></label>
                                                            {loading ? <div class="col-sm-10">
                                                                <Loader />
                                                            </div>
                                                                :
                                                                null
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-12">
                                        <div class="card-box">
                                            <h4 class="m-t-0 header-title">ACil Ve Hizli Motor Kurye</h4>
                                            <p class="text-muted mb-4 font-14">
                                            </p>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="p-2">

                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">Hizmet Bedeli</label>
                                                            <div class="col-sm-10">
                                                                <input type="number" id="simpleinput" value={hizmetbedeliAC} onChange={handleHizmetBedeliAC} class="form-control" placeholder="Hizmet Bedeli (Acil Ve Hizli Motor Kurye)" />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">Km Basi</label>
                                                            <div class="col-sm-10">
                                                                <input type="number" id="simpleinput" value={kmbasiAC} onChange={handleKmBasiAc} class="form-control" placeholder="Km Basi (Acil Ve Hizli Motor Kurye)" />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">1 - 5 (KM)</label>
                                                            <div class="col-sm-10">
                                                                <input type="number" id="simpleinput" value={ac_1_5} onChange={handleAC_1_5} class="form-control" placeholder='Km Fiyat (Acil Ve Hizli Motor Kurye' />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">6 - 11 (KM)</label>
                                                            <div class="col-sm-10">
                                                                <input type="number" id="simpleinput" value={ac_6_11} onChange={handleAC_6_11} class="form-control" placeholder='Km Fiyat (Acil Ve Hizli Motor Kurye' />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">12 - 18 (KM)</label>
                                                            <div class="col-sm-10">
                                                                <input type="number" id="simpleinput" value={ac_12_18} onChange={handleAC_12_18} class="form-control" placeholder='Km Fiyat (Acil Ve Hizli Motor Kurye' />
                                                            </div>
                                                        </div>


                                                        {
                                                            errorAC ?
                                                                <div class="form-group row">
                                                                    <label class="col-sm-2  col-form-label" for="simpleinput"></label>
                                                                    <div class="col-sm-10">
                                                                        <p style={{ color: 'red' }}>Bilgilerinizi kontrol edin lütfen!</p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput"></label>
                                                            {loading ? <div class="col-sm-10">
                                                                <Loader />
                                                            </div>
                                                                :
                                                                null
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="card-box">
                                            <h4 class="m-t-0 header-title">Bilgileri</h4>
                                            <p class="text-muted mb-4 font-14">
                                            </p>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="p-2">

                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">Lokanta</label>
                                                            <div class="col-sm-10">
                                                                <Switch onChange={lokantaSwicth} checked={lokantacheck} />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">Cicek</label>
                                                            <div class="col-sm-10">
                                                                <Switch onChange={cicekSwicth} checked={cicekcheck} />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">Kurye kesilecek yüzdesi</label>
                                                            <div class="col-sm-10">
                                                                <input type="number" value={yuzde} onChange={handleYuzde} id="simpleinput" class="form-control" placeholder='yuzde %' />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">Desktek Numara</label>
                                                            <div class="col-sm-10">
                                                                <input type="text" value={desteknumara} onChange={handleDestekNumara} id="simpleinput" class="form-control" placeholder='Destek Numara' />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">APIKEY</label>
                                                            <div class="col-sm-10">
                                                                <input type="text" disabled value={apikey} onChange={handleApiKey} id="simpleinput" class="form-control" placeholder='APIKEY' />
                                                            </div>
                                                        </div>

                                                        {
                                                            errorBI ?
                                                                <div class="form-group row">
                                                                    <label class="col-sm-2  col-form-label" for="simpleinput"></label>
                                                                    <div class="col-sm-10">
                                                                        <p style={{ color: 'red' }}>Bilgilerinizi kontrol edin lütfen!</p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput"></label>
                                                            {loading ? <div class="col-sm-10">
                                                                <Loader />
                                                            </div>
                                                                :
                                                                <div class="col-sm-10">
                                                                    <div onClick={handleUpdateAppSettings} class="btn btn-primary">Kaydet</div>
                                                                    <Link to='/' class="btn btn-success" style={{ marginLeft: 10, color: 'white' }}>Geri don</Link>
                                                                </div>
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }

                </div>
            </div>
            <Footer />
        </div>
    )
};
export default Settings;