// IMPORT DEPENDENCIES
import { BASE_URL } from '../../urls/base/base';
import { UPLOAD_URL } from "../../urls/base/base";

export const KURYE_LIST_URL = `${BASE_URL}/admin/getkurye`;
export const ADD_KURYE_URL = `${BASE_URL}/admin/addkurye`;
export const UPDATE_KURYE_URL = `${BASE_URL}/admin/updatekurye`;
export const DELETE_KURYE_URL = `${BASE_URL}/admin/deletekurye`;
export const GET_KURYE_CITY__URL = `${BASE_URL}/admin/getkuryecity`;
export const SEARCH_KURYE__URL = `${BASE_URL}/admin/searchkurye`;
export const KURYE_PARASI_URL = `${BASE_URL}/admin/kuryeparasi`;
export const KURYE_USER_INFOS_URL = `${BASE_URL}/admin/getkuryeuserinfos`;
export const SIFRE_SIFIRLA_URL = `${BASE_URL}/admin/kuryesifresifirlamak`;
export const GET_APP_INFOS_URL = `${BASE_URL}/admin/getappinfos`;
export const GET_USERS_URL = `${BASE_URL}/admin/getusers`;
export const SEARCH_USER_URL = `${BASE_URL}/admin/searchuser`;
export const GET_SIPARIS_URL = `${BASE_URL}/admin/getsiparis`;
export const GET_APP_SETTINGS_URL = `${BASE_URL}/admin/getappsettings`;
export const UPDATE_APP_SETTINGS_URL = `${BASE_URL}/admin/updateappsettings`;
export const ADD_LOKANTA_URL = `${BASE_URL}/admin/addlokanta`;
export const UPLOAD_FILE_URL = `${UPLOAD_URL}/aninda/uploadfile`;
export const GET_LOKANTA_URL = `${BASE_URL}/admin/getlokanta`;
export const SEARCH_LOKANTA_URL = `${BASE_URL}/admin/searchlokanta`;
export const UPDATE_LOKANTA_URL = `${BASE_URL}/admin/updatelokanta`;
export const DELETE_LOKANTA_URL = `${BASE_URL}/admin/deletelokanta`;
export const LOKANTA_USER_INFOS_URL = `${BASE_URL}/admin/getlokantauserinfos`;
export const LOKANTA_ENGELLE_URL = `${BASE_URL}/admin/lokantalocked`;
export const LOKANTA_SIFIRLA_URL = `${BASE_URL}/admin/lokantasifresifirlamak`;
export const ADD_CICEK_URL = `${BASE_URL}/admin/addcicek`;
export const GET_CICEK_URL = `${BASE_URL}/admin/getcicek`;
export const SEARCH_CICEK_URL = `${BASE_URL}/admin/searchcicek`;
export const UPDATE_CICEK_URL = `${BASE_URL}/admin/updatecicek`;
export const DELETE_CICEK_URL = `${BASE_URL}/admin/deletecicek`;
export const CICEK_USER_INFOS_URL = `${BASE_URL}/admin/getcicekuserinfos`;
export const CICEK_ENGELLE_URL = `${BASE_URL}/admin/ciceklocked`;
export const CICEK_SIFIRLA_URL = `${BASE_URL}/admin/ciceksifresifirlamak`;
export const GET_SIPARIS_NEW_URL = `${BASE_URL}/admin/getsiparisnew`;