import * as actionTypes from '../../types/admin/admin'

const initialState = {
    error: null,
    loading: false,
    kuryelist: [],
    addkuryestatus: false,
    updatekuryestatus: false,
    kuryeupdatedata: [],
    kuryeupdatedatastatus: false,
    deletekuryestatus: false,
    kuryedeletedata: [],
    kuryedeletedatastatus: false,
    kuryeparasidata: [],
    kuryeparasistatus: false,
    kuryepara: false,
    sifresifirlamadata: [],
    sifresifirlamastatus: false,
    loadingsifir: false,
    kuryeuserinfo: [],
    sifrestatus: false,
    userinfo: 0,
    kuryeinfo: 0,
    lokantainfo: 0,
    cicekinfo: 0,
    userlist: [],
    siparislist: [],
    loadingsettings: false,
    appsettingslist: [],
    hizmetbedeliMK: null,
    hizmetbedeliAC: null,
    kmbasiMK: null,
    kmbasiAC: null,
    mk_1_5: null,
    mk_6_11: null,
    mk_12_18: null,
    ac_1_5: null,
    ac_6_11: null,
    ac_12_18: null,
    lokantacheck: false,
    cicekcheck: false,
    apikey: null,
    desteknumara: null,
    yuzde: null,
    updateappsettingstatus: false,
    addlokantastatus: false,
    logolokanta: null,
    loadingupload: false,
    lokantalist: [],
    lokantadata: [],
    updatelokantadatastatus: false,
    updatelokanta: false,
    deletelokantadatastatus: false,
    deletelokanta: false,
    lokantauserinfo: [],
    lokantalockedstatus: false,
    lokantaloackeddata: [],
    lokantalockedstat: false,
    lokantasifirlamakstatus: false,
    lokantasirlamaktatus: false,

    addcicekstatus: false,
    logocicek: null,
    ciceklist: [],
    updatecicekdatastatus: false,
    updatecicek: false,
    cicekdata: [],
    deletecicekdatastatus: false,
    deletecicek: false,
    ciceklockedstatus: false,
    ciceksifirlamakstatus: false,
    cicekuserinfo: [],
    cicekloackeddata: [],
    ciceklockedstat: false,

    topludagitim: null,
    newsiparislist: [],
    siparisdetail: [],
    modal: false

}

const ReducerAdmin = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_KURYE_START:
            return {
                ...state,
                loading: true,
                error: null,
                addkuryestatus: false,
                updatekuryestatus: false,
                deletekuryestatus: false,
                kuryepara: false,
                sifrestatus: false
            }
        case actionTypes.GET_KURYE_SUCCESS:
            return {
                ...state,
                loading: false,
                kuryelist: action.kuryelist,
                error: null
            }
        case actionTypes.GET_KURYE_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.ADD_KURYE_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.ADD_KURYE_SUCCESS:
            return {
                ...state,
                loading: false,
                addkuryestatus: true,
                error: null
            }
        case actionTypes.ADD_KURYE_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.UPDATE_KURYE_DATA:
            return {
                ...state,
                kuryeupdatedata: action.kuryeupdatedata,
                kuryeupdatedatastatus: true
            }
        case actionTypes.UPDATE_KURYE_DATA_CANCEL:
            return {
                ...state,
                kuryeupdatedatastatus: false
            }
        case actionTypes.UPDATE_KURYE_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.UPDATE_KURYE_SUCCESS:
            return {
                ...state,
                loading: false,
                updatekuryestatus: true,
                error: null
            }
        case actionTypes.UPDATE_KURYE_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.DELETE_KURYE_DATA:
            return {
                ...state,
                kuryedeletedata: action.kuryedeletedata,
                kuryedeletedatastatus: true
            }
        case actionTypes.DELETE_KURYE_DATA_CANCEL:
            return {
                ...state,
                kuryedeletedatastatus: false
            }
        case actionTypes.DELETE_KURYE_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.DELETE_KURYE_SUCCESS:
            return {
                ...state,
                loading: false,
                deletekuryestatus: true,
                error: null
            }
        case actionTypes.DELETE_KURYE_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.GET_KURYE_CITY_START:
            return {
                ...state,
                loading: true,
                error: null,
                addkuryestatus: false,
                updatekuryestatus: false,
                deletekuryestatus: false
            }
        case actionTypes.GET_KURYE_CITY_SUCCESS:
            return {
                ...state,
                loading: false,
                kuryelist: action.kuryelist,
                error: null
            }
        case actionTypes.GET_KURYE_CITY_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.SEARCH_KURYE_START:
            return {
                ...state,
                loading: true,
                error: null,
                addkuryestatus: false,
                updatekuryestatus: false,
                deletekuryestatus: false
            }
        case actionTypes.SEARCH_KURYE_SUCCESS:
            return {
                ...state,
                loading: false,
                kuryelist: action.kuryelist,
                error: null
            }
        case actionTypes.SEARCH_KURYE_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.UPDATE_KURYE_PARASI:
            return {
                ...state,
                kuryeparasidata: action.kuryeparasidata,
                kuryeparasistatus: true
            }
        case actionTypes.UPDATE_KURYE_PARASI_CANCEL:
            return {
                ...state,
                kuryeparasistatus: false
            }
        case actionTypes.KURYE_PARASI_START:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case actionTypes.KURYE_PARASI_SUCCESS:
            return {
                ...state,
                loading: false,
                kuryepara: true,
                error: null
            }
        case actionTypes.KURYE_PARASI_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.UPDATE_SIFRE_SIFIRLAMA:
            return {
                ...state,
                sifresifirlamadata: action.sifresifirlamadata,
                sifresifirlamastatus: true
            }
        case actionTypes.UPDATE_KURYE_PARASI_CANCEL:
            return {
                ...state,
                sifresifirlamastatus: false
            }
        case actionTypes.GET_KURYE_USER_INFO_START:
            return {
                ...state,
                loadingsifir: true,
                sifresifirlamastatus: false,
                error: null,
            }
        case actionTypes.GET_KURYE_USER_INFO_SUCCESS:
            return {
                ...state,
                loadingsifir: false,
                kuryeuserinfo: action.kuryeuserinfo,
                error: null
            }
        case actionTypes.GET_KURYE_USER_INFO_FAILED:
            return {
                ...state,
                error: action.error,
                loadingsifir: false
            }
        case actionTypes.SIFRE_SIFIRLA_START:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case actionTypes.SIFRE_SIFIRLA_SUCCESS:
            return {
                ...state,
                loading: false,
                sifrestatus: true,
                error: null
            }
        case actionTypes.SIFRE_SIFIRLA_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.GET_APP_INFO_START:
            return {
                ...state,
                loading: true,
                error: null,
                updateappsettingstatus: false
            }
        case actionTypes.GET_APP_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                userinfo: action.userinfo,
                kuryeinfo: action.kuryeinfo,
                lokantainfo: action.lokantainfo,
                cicekinfo: action.cicekinfo,
                error: null
            }
        case actionTypes.GET_APP_INFO_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.GET_USER_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.GET_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                userlist: action.userlist,
                error: null
            }
        case actionTypes.GET_USER_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.SEARCH_USER_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.SEARCH_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                userlist: action.userlist,
                error: null
            }
        case actionTypes.SEARCH_USER_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.GET_SIPARIS_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.GET_SIPARIS_SUCCESS:
            return {
                ...state,
                loading: false,
                siparislist: action.siparislist,
                error: null
            }
        case actionTypes.GET_SIPARIS_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.GET_APP_SETTINGS_START:
            return {
                ...state,
                loadingsettings: true,
                error: null
            }
        case actionTypes.GET_APP_SETTINGS_SUCCESS:
            return {
                ...state,
                loadingsettings: false,
                appsettingslist: action.appsettingslist,
                hizmetbedeliMK: action.hizmetbedelimotorkurye,
                hizmetbedeliAC: action.hizmetbedeliacilvehizli,
                kmbasiMK: action.kmbasimotorkurye,
                kmbasiAC: action.kmbasiacilveizli,
                mk_1_5: action.motorkurye_1_5,
                mk_6_11: action.motorkurye_6_11,
                mk_12_18: action.motorkurye_12_18,
                ac_1_5: action.acilvehizli_1_5,
                ac_6_11: action.acilvehizli_6_11,
                ac_12_18: action.acilvehizli_12_18,
                lokantacheck: action.lokanta,
                cicekcheck: action.cicek,
                apikey: action.apikey,
                desteknumara: action.number,
                yuzde: action.yuzde,
                topludagitim: action.topludagitim,
                error: null
            }
        case actionTypes.GET_APP_SETTINGS_FAILED:
            return {
                ...state,
                error: action.error,
                loadingsettings: false
            }
        case actionTypes.HIZMET_BEDELI_MK:
            return {
                ...state,
                hizmetbedeliMK: action.hizmetbedeliMK,
            }
        case actionTypes.HIZMET_BEDELI_AC:
            return {
                ...state,
                hizmetbedeliAC: action.hizmetbedeliAC,
            }
        case actionTypes.KM_BASI_MK:
            return {
                ...state,
                kmbasiMK: action.kmbasiMK,
            }
        case actionTypes.KM_BASI_AC:
            return {
                ...state,
                kmbasiAC: action.kmbasiAC,
            }
        case actionTypes.MK_1_5:
            return {
                ...state,
                mk_1_5: action.mk_1_5,
            }
        case actionTypes.MK_6_11:
            return {
                ...state,
                mk_6_11: action.mk_6_11,
            }
        case actionTypes.MK_12_18:
            return {
                ...state,
                mk_12_18: action.mk_12_18,
            }
        case actionTypes.AC_1_5:
            return {
                ...state,
                ac_1_5: action.ac_1_5,
            }
        case actionTypes.AC_6_11:
            return {
                ...state,
                ac_6_11: action.ac_6_11,
            }
        case actionTypes.AC_12_18:
            return {
                ...state,
                ac_12_18: action.ac_12_18,
            }
        case actionTypes.LOKANTA_SWITCH:
            return {
                ...state,
                lokantacheck: !action.lokanta,
            }
        case actionTypes.CICEK_SWITCH:
            return {
                ...state,
                cicekcheck: !action.cicek
            }
        case actionTypes.API_KEY:
            return {
                ...state,
                apikey: action.apikey
            }
        case actionTypes.DESTEK_NUMARA:
            return {
                ...state,
                desteknumara: action.desteknumara
            }
        case actionTypes.YUZDE:
            return {
                ...state,
                yuzde: action.yuzde
            }

        case actionTypes.TOPLUDAGITIM:
            return {
                ...state,
                topludagitim: action.topludagitim
            }
        case actionTypes.UPDATE_APP_SETTINGS_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.UPDATE_APP_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                updateappsettingstatus: true,
                error: null
            }
        case actionTypes.UPDATE_APP_SETTINGS_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.ADD_LOKANTA_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.ADD_LOKANTA_SUCCESS:
            return {
                ...state,
                loading: false,
                addlokantastatus: true,
                logolokanta: null,
                error: null
            }
        case actionTypes.ADD_LOKANTA_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.UPLOAD_FILE_START:
            return {
                ...state,
                loadingupload: true,
                error: null
            }
        case actionTypes.UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                loadingupload: false,
                logolokanta: action.logo,
                error: null
            }
        case actionTypes.UPLOAD_FILE_FAILED:
            return {
                ...state,
                error: action.error,
                loadingupload: false
            }
        case actionTypes.GET_LOKANTA_START:
            return {
                ...state,
                loading: true,
                error: null,
                addlokantastatus: false,
                updatelokanta: false,
                deletelokanta: false,
                lokantalockedstat: false,
                lokantasirlamaktatus: false
            }
        case actionTypes.GET_LOKANTA_SUCCESS:
            return {
                ...state,
                loading: false,
                lokantalist: action.lokantalist,
                error: null
            }
        case actionTypes.GET_LOKANTA_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.SEARCH_LOKANTA_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.SEARCH_LOKANTA_SUCCESS:
            return {
                ...state,
                loading: false,
                lokantalist: action.lokantalist,
                error: null
            }
        case actionTypes.SEARCH_LOKANTA_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.UPDATE_LOKANTA_DATA:
            return {
                ...state,
                lokantadata: action.lokantadata,
                updatelokantadatastatus: true
            }
        case actionTypes.UPDATE_LOKANTA_DATA_CANCEL:
            return {
                ...state,
                updatelokantadatastatus: false
            }
        case actionTypes.UPDATE_LOKANTA_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.UPDATE_LOKANTA_SUCCESS:
            return {
                ...state,
                loading: false,
                updatelokanta: true,
                error: null
            }
        case actionTypes.UPDATE_LOKANTA_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.DELETE_LOKANTA_DATA:
            return {
                ...state,
                lokantadata: action.lokantadata,
                deletelokantadatastatus: true
            }
        case actionTypes.DELETE_LOKANTA_DATA_CANCEL:
            return {
                ...state,
                deletelokantadatastatus: false
            }
        case actionTypes.DELETE_LOKANTA_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.DELETE_LOKANTA_SUCCESS:
            return {
                ...state,
                loading: false,
                deletelokanta: true,
                error: null
            }
        case actionTypes.DELETE_LOKANTA_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.GET_LOKANTA_USER_INFO_START:
            return {
                ...state,
                loadingsifir: true,
                lokantalockedstatus: false,
                lokantasifirlamakstatus: false,
                error: null,
            }
        case actionTypes.GET_LOKANTA_USER_INFO_SUCCESS:
            return {
                ...state,
                loadingsifir: false,
                lokantauserinfo: action.lokantauserinfo,
                error: null
            }
        case actionTypes.GET_LOKANTA_USER_INFO_FAILED:
            return {
                ...state,
                error: action.error,
                loadingsifir: false
            }
        case actionTypes.LOCKED_LOKANTA:
            return {
                ...state,
                lokantalockedstatus: true,
                lokantaloackeddata: action.lokantaloackeddata,
                error: null,
            }
        case actionTypes.LOKANTA_LOCKED_START:
            return {
                ...state,
                loading: false,
                error: null
            }
        case actionTypes.LOKANTA_LOCKED_SUCCESS:
            return {
                ...state,
                error: action.error,
                loading: false,
                lokantalockedstat: true,
            }
        case actionTypes.LOKANTA_LOCKED_FAILED:
            return {
                ...state,
                loading: false,
                error: null
            }
        case actionTypes.SIFIRLAMA_LOKANTA:
            return {
                ...state,
                lokantasifirlamakstatus: true,
                lokantaloackeddata: action.lokantaloackeddata,
                error: null,
            }
        case actionTypes.LOKANTA_SIFIRLA_START:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case actionTypes.LOKANTA_SIFIRLA_SUCCESS:
            return {
                ...state,
                loading: false,
                lokantasirlamaktatus: true,
                error: null
            }
        case actionTypes.LOKANTA_SIFIRLA_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.ADD_CICEK_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.ADD_CICEK_SUCCESS:
            return {
                ...state,
                loading: false,
                addcicekstatus: true,
                logocicek: null,
                error: null
            }
        case actionTypes.ADD_CICEK_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.GET_CICEK_START:
            return {
                ...state,
                loading: true,
                error: null,
                addcicekstatus: false,
                updatecicek: false,
                deletecicek: false,
                ciceklockedstat: false,
                ciceksirlamaktatus: false
            }
        case actionTypes.GET_CICEK_SUCCESS:
            return {
                ...state,
                loading: false,
                ciceklist: action.ciceklist,
                error: null
            }
        case actionTypes.GET_CICEK_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.SEARCH_CICEK_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.SEARCH_CICEK_SUCCESS:
            return {
                ...state,
                loading: false,
                ciceklist: action.ciceklist,
                error: null
            }
        case actionTypes.SEARCH_CICEK_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.UPDATE_CICEK_DATA:
            return {
                ...state,
                cicekdata: action.cicekdata,
                updatecicekdatastatus: true
            }
        case actionTypes.UPDATE_CICEK_DATA_CANCEL:
            return {
                ...state,
                updatecicekdatastatus: false
            }
        case actionTypes.UPDATE_CICEK_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.UPDATE_CICEK_SUCCESS:
            return {
                ...state,
                loading: false,
                updatecicek: true,
                error: null
            }
        case actionTypes.UPDATE_CICEK_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.DELETE_CICEK_DATA:
            return {
                ...state,
                cicekdata: action.cicekdata,
                deletecicekdatastatus: true
            }
        case actionTypes.DELETE_CICEK_DATA_CANCEL:
            return {
                ...state,
                deletecicekdatastatus: false
            }
        case actionTypes.DELETE_CICEK_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.DELETE_CICEK_SUCCESS:
            return {
                ...state,
                loading: false,
                deletecicek: true,
                error: null
            }
        case actionTypes.DELETE_CICEK_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.GET_CICEK_USER_INFO_START:
            return {
                ...state,
                loadingsifir: true,
                ciceklockedstatus: false,
                ciceksifirlamakstatus: false,
                error: null,
            }
        case actionTypes.GET_CICEK_USER_INFO_SUCCESS:
            return {
                ...state,
                loadingsifir: false,
                cicekuserinfo: action.cicekuserinfo,
                error: null
            }
        case actionTypes.GET_CICEK_USER_INFO_FAILED:
            return {
                ...state,
                error: action.error,
                loadingsifir: false
            }
        case actionTypes.LOCKED_CICEK:
            return {
                ...state,
                ciceklockedstatus: true,
                cicekloackeddata: action.cicekloackeddata,
                error: null,
            }
        case actionTypes.CICEK_LOCKED_START:
            return {
                ...state,
                loading: false,
                error: null
            }
        case actionTypes.CICEK_LOCKED_SUCCESS:
            return {
                ...state,
                error: action.error,
                loading: false,
                ciceklockedstat: true,
            }
        case actionTypes.CICEK_LOCKED_FAILED:
            return {
                ...state,
                loading: false,
                error: null
            }
        case actionTypes.SIFIRLAMA_CICEK:
            return {
                ...state,
                ciceksifirlamakstatus: true,
                cicekloackeddata: action.cicekloackeddata,
                error: null,
            }
        case actionTypes.CICEK_SIFIRLA_START:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case actionTypes.CICEK_SIFIRLA_SUCCESS:
            return {
                ...state,
                loading: false,
                ciceksirlamaktatus: true,
                error: null
            }
        case actionTypes.CICEK_SIFIRLA_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.GET_SIPARIS_NEW_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.GET_SIPARIS_NEW_SUCCESS:
            return {
                ...state,
                loading: false,
                newsiparislist: action.newsiparislist,
                error: null
            }
        case actionTypes.GET_SIPARIS_NEW_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false
            }

        case actionTypes.GET_SIPARIS_DETAIL:
            return {
                ...state,
                siparisdetail: action.siparisdetail,
                modal: true
            }

        case actionTypes.OPEN_MODAL:
            return {
                ...state,
                modal: true
            }

        case actionTypes.CLOSE_MODAL:
            return {
                ...state,
                modal: false
            }
        default:
            return state
    }
}
export default ReducerAdmin