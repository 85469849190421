import React, { useEffect, useState } from 'react';
import Decode from 'jwt-decode';

// IMPORT DEPENDENCIES
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCicekDataCancel, updateCicekService } from '../../actions/admin/admin';

//IMPORT COMPONENT
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Loader from '../../components/loader/loader';

const UpdateCicek = () => {

    const Dispacth = useDispatch();

    const token = useSelector(state => state.AuthReducer.token);
    const loading = useSelector(state => state.AdminReducer.loading);
    const updatecicek = useSelector(state => state.AdminReducer.updatecicek);
    const cicekdata = useSelector(state => state.AdminReducer.cicekdata);

    const [cicekAdi, setCicekAdi] = useState(cicekdata.name);
    const [sahibiAdi, setSahibiAdi] = useState(cicekdata.sahibi);
    const [sahibiNumber, setSahibiNumber] = useState(cicekdata.telephone);
    const [error, setError] = useState(false);


    useEffect(() => {
        Dispacth(updateCicekDataCancel());
    }, []);

 

    const handleCicekAdi = (e) => {
        setCicekAdi(e.target.value);
        setError(false);
    }
    const handleSahibiAdi = (e) => {
        setSahibiAdi(e.target.value);
        setError(false);
    }
   
    const handleSahibiNumber = (e) => {
        setSahibiNumber(e.target.value);
        setError(false);
    }
  

    const handleSubmit = (e) => {
        e.preventDefault();
        if (cicekAdi !== '' && cicekAdi.trim() !== '' && sahibiAdi !== '' && sahibiAdi.trim() !== ''  && sahibiNumber !== '' && sahibiNumber.trim() !== '' ) {
            Dispacth(updateCicekService(cicekAdi, sahibiAdi, sahibiNumber, cicekdata.id, token));
        }
        else {
            setError(true);
        }
    }


    let PageRedirect = null
    if (updatecicek) {
        PageRedirect = <Redirect to="/cicek-list" />
    }

    return (
        <div>
            <Header />
            <div class="wrapper">
                <div class="container-fluid">
                    {PageRedirect}
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box">
                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Aninda Bi'Kurye</a></li>
                                        <li class="breadcrumb-item"><a href="javascript: void(0);"> Cicek Guncellemek</a></li>
                                        <li class="breadcrumb-item active">Cicek Guncellemek</li>
                                    </ol>
                                </div>
                                <h4 class="page-title"></h4>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="card-box">
                                <h4 class="m-t-0 header-title">Cicek Guncellemek</h4>
                                <p class="text-muted mb-4 font-14">
                                </p>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="p-2">
                                            <form class="form-horizontal" role="form" onSubmit={handleSubmit} >
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Lokanta Adi</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={cicekAdi} class="form-control" onChange={handleCicekAdi} placeholder="lokanta adı" />
                                                    </div>
                                                </div>
                                                {/* <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Lokanta Logo</label>
                                                    {loadingupload ?
                                                        <div class="col-sm-10">
                                                            <Loader />
                                                        </div>
                                                        :
                                                        logolokanta !== null ?
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">{logolokanta}</label>
                                                            :
                                                            <div class="col-sm-10">
                                                                <input type="file" name="file" class="form-control" onChange={handleLokantaLogo} placeholder="lokanta logo" />
                                                            </div>
                                                    }

                                                </div> */}
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Sahibi Adi</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={sahibiAdi} class="form-control" onChange={handleSahibiAdi} placeholder="Sahibi Adi soyadi" />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Sahibi Numara</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={sahibiNumber} class="form-control" onChange={handleSahibiNumber} placeholder='Lokanta Numara' />
                                                    </div>
                                                </div>
                                                {/* <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Telefon</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={tel} class="form-control" onChange={handleTel} placeholder='05...' />
                                                    </div>
                                                </div> */}
                                               
                                                {
                                                    error ?
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput"></label>
                                                            <div class="col-sm-10">
                                                                <p style={{ color: 'red' }}>Bilgilerinizi kontrol edin lütfen!</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput"></label>
                                                    {loading ? <div class="col-sm-10">
                                                        <Loader />
                                                    </div>
                                                        :
                                                        <div class="col-sm-10">
                                                            <button type="submit" class="btn btn-primary">Kaydet</button>
                                                            <Link to='/cicek-list' class="btn btn-success" style={{ marginLeft: 10, color: 'white' }}>Geri don</Link>
                                                        </div>
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default UpdateCicek