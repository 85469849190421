import React from 'react';

// IMPORT DEPENDENCIES
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../actions/auth/auth';

// IMPORT IMAGES
import LogoSm from '../../assets/images/delivery.png';

const Header = () => {

    const Dispacth = useDispatch();

    const username = useSelector(state => state.AuthReducer.name);
    const name = useSelector(state => state.AuthReducer.name);

    return (
        <header id="topnav">
            <div className="navbar-custom">
                <div className="container-fluid">
                    <ul className="list-unstyled topnav-menu float-right mb-0">
                        <li className="dropdown notification-list">
                            <a className="navbar-toggle nav-link">
                                <div className="lines">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </a>
                        </li>
                        <li className="d-none d-sm-block">
                            <form className="app-search">
                                <div className="app-search-box">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Arama..." />
                                        <div className="input-group-append">
                                            <button className="btn" type="submit">
                                                <i className="fe-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </li>
                        <li className="dropdown notification-list">
                            <a className="nav-link dropdown-toggle waves-effect" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                <i className="fe-bell noti-icon"></i>
                                <span className="badge badge-danger rounded-circle noti-icon-badge">0</span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right dropdown-lg">
                                <div className="dropdown-item noti-title">
                                    <h5 className="m-0">
                                        <span className="float-right">
                                            <a href="" className="text-dark">
                                                <small>Hepsini temizle</small>
                                            </a>
                                        </span>Bildirim
                                    </h5>
                                </div>
                                <div className="slimscroll noti-scroll">
                                    <a href="javascript:void(0);" className="dropdown-item notify-item">
                                        <div className="notify-icon bg-secondary">
                                            <i className="mdi mdi-heart"></i>
                                        </div>
                                        <p className="notify-details">Bildirim yok
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </li>
                        <li className="dropdown notification-list">
                            <a className="nav-link dropdown-toggle nav-user mr-0 waves-effect" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                <img src={LogoSm} alt="user-image" className="rounded-circle" />
                                <span className="pro-user-name ml-1">
                                    {username}
                                    <i className="mdi mdi-chevron-down"></i>
                                </span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                                <div className="dropdown-header noti-title">
                                    <h6 className="text-overflow m-0">{name}</h6>
                                </div>
                                <div className="dropdown-divider"></div>
                                <span className="dropdown-item notify-item">
                                    <i className="fe-log-out"></i>
                                    {/* <span onClick={this.Deconnect} style={{ cursor: 'pointer' }}>Logout</span> */}
                                    <span onClick={() => Dispacth(logOut())} style={{ cursor: 'pointer' }}>Çıkış Yap</span>
                                </span>
                            </div>
                        </li>
                        <li className="dropdown notification-list">
                            <Link to="/settings" className="nav-link right-bar-toggle waves-effect">
                                <i className="fe-settings noti-icon"></i>
                            </Link>
                        </li>
                    </ul>
                    <div className="logo-box d-flex justify-content-center align-items-center">
                        <Link to="/" className="logo text-center">
                            <span className="logo-lg">
                                <h3 className="text-white">Paneli</h3>
                            </span>
                            <span className="logo-sm">
                                <img src={LogoSm} alt="" height="24" />
                            </span>
                        </Link>
                    </div>

                </div>
            </div>
            <div className="topbar-menu">
                <div className="container-fluid">
                    <div id="navigation">
                        <ul className="navigation-menu">
                            <li className="has-submenu">
                                <Link to="/"><i className="mdi mdi-view-dashboard"></i>Ana Sayfa</Link>
                            </li>
                            <li className="has-submenu">
                                <a href="#"> <i className="mdi mdi-invert-colors"></i>Kullanıcılar<div className="arrow-down"></div></a>
                                <ul className="submenu megamenu">
                                    <li>
                                        <ul>
                                            <li>
                                                <Link to="/user-list" href="ui-buttons.html">Kullanıcı Listesi</Link>
                                            </li>
                                            {/* <li>
                                                <a href="ui-cards.html">Yeni Kullanıcı Kaydet</a>
                                            </li> */}
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className="has-submenu">
                                <a href="#"> <i className="mdi mdi-chart-donut-variant"></i>Kuryeler<div className="arrow-down"></div></a>
                                <ul className="submenu megamenu">
                                    <li>
                                        <ul>
                                            <li>
                                                <Link to='/kurye-listesi'>Kuryeler Listesi</Link>
                                                <Link to='/yeni-kurye-kaydet'>Yeni Kurye Kaydet</Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className="has-submenu">
                                <a href="#"> <i className="mdi mdi-chart-donut-variant"></i>Siparişler<div className="arrow-down"></div></a>
                                <ul className="submenu megamenu">
                                    <li>
                                        <ul>
                                            <li>
                                                <Link to='/siparis-listesi'>Siparişler Listesi</Link>
                                                <Link to='/newsiparislist'>Yeni Sipariş Listesi</Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li className="has-submenu">
                                <a href="#"> <i className="mdi mdi-chart-donut-variant"></i>Lokanta<div className="arrow-down"></div></a>
                                <ul className="submenu megamenu">
                                    <li>
                                        <ul>
                                            <li>
                                                <Link to='/lokanta-list'>Lokanta Listesi</Link>
                                                <Link to='/yenilokanta'>Yeni Lokanta Kaydet</Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li className="has-submenu">
                                <a href="#"> <i className="mdi mdi-chart-donut-variant"></i>Cicek<div className="arrow-down"></div></a>
                                <ul className="submenu megamenu">
                                    <li>
                                        <ul>
                                            <li>
                                                <Link to='/cicek-list'>Cicek Listesi</Link>
                                                <Link to='/yenicicek'>Yeni Cicek Kayget</Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li className="has-submenu">
                                <a href="#"> <i className="mdi mdi-card-bulleted-settings-outline"></i>Bildirimler<div className="arrow-down"></div></a>
                                <ul className="submenu">
                                    <li>
                                        <Link to='/category-list'>Kullanıcılara bildirim gönder</Link>
                                    </li>
                                    <li>
                                        <Link to='/create-category'>Kuryelere bildirim gönder</Link>
                                    </li>
                                    <li>
                                        <Link to='/create-category'>Tüm kisilere bildirim gönder</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="has-submenu">
                                <a href="#"> <i className="mdi mdi-card-bulleted-settings-outline"></i>Finans<div className="arrow-down"></div></a>
                                <ul className="submenu">
                                    <li>
                                        <Link to='/category-list'>Finansa Genel Bakış</Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header