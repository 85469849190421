import React, { useEffect, useState } from 'react';
import Decode from 'jwt-decode';

// IMPORT DEPENDENCIES
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLokantaService, updateLokantaData, deleteLokantaData, lockedLokanta, searchLokantaService, sifirlamakLokanta, updateSifreSifirlama } from '../../actions/admin/admin';
import Moment from 'react-moment';
import 'moment/locale/tr';

//IMPORT COMPONENT
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Loader from '../../components/loader/loader';

const LokantaList = (props) => {

    const Dispatch = useDispatch();
    const token = useSelector(state => state.AuthReducer.token);
    const loading = useSelector(state => state.AdminReducer.loading);
    const lokantalist = useSelector(state => state.AdminReducer.lokantalist);
    const updatelokantadatastatus = useSelector(state => state.AdminReducer.updatelokantadatastatus);
    const deletelokantadatastatus = useSelector(state => state.AdminReducer.deletelokantadatastatus);
    const lokantalockedstatus = useSelector(state => state.AdminReducer.lokantalockedstatus);
    const lokantasifirlamakstatus = useSelector(state => state.AdminReducer.lokantasifirlamakstatus);

    const username = useSelector(state => state.AuthReducer.username);


   
    const [name, setName] = useState('')

    var decoded = Decode(token)

    useEffect(() => {
        Dispatch(getLokantaService(token));
    }, []);

    const deleteRestaurant = (item) => {
         Dispatch(deleteLokantaData(item))
    }

    const updateLokantaDataKurye = (item) => {
         Dispatch(updateLokantaData(item))
    }

    const lockLokanta = (item) => {
         Dispatch(lockedLokanta(item))
    }

    const sifirlamaSifre = (item) => {
        Dispatch(sifirlamakLokanta(item))
    }


    const handleName = (e) => {
        setName(e.target.value)
        Dispatch(searchLokantaService(e.target.value, token))
    }

    let LokantaMap = lokantalist.map((item, index) => {
        return (
            <tr key={index}>

                <td>
                    <img style={{ width: 45, height: 45 }}
                        src={item.logo}
                        alt={item.logo}
                    />
                </td>
                <td>{item.name}</td>
                <td>{item.sahibi}</td>
                <td>{item.telephone}</td>
                <td> <Moment fromNow>{item.createdAt}</Moment> </td>
                <td>
                    <i onClick={() => updateLokantaDataKurye(item)} class="ti-pencil" style={{ color: 'green', fontSize: 18, fontWeight: 'bold', cursor: 'pointer' }}></i>
                    <i onClick={() => deleteRestaurant(item)} class="ti-trash" style={{ color: 'red', fontSize: 18, marginLeft: 15, cursor: 'pointer' }}></i>
                    <i onClick={() => lockLokanta(item)} class="ti-lock" style={{ color: 'blue', fontSize: 18, marginLeft: 15, cursor: 'pointer' }}></i>
                    <i onClick={() => sifirlamaSifre(item)} class="ti-user" style={{ color: 'orange', fontSize: 18, marginLeft: 15, cursor: 'pointer' }}></i>
                </td>
            </tr>
        )
    })

    let PageRedirect = null;

     if (updatelokantadatastatus) {
         PageRedirect = <Redirect to="/lokanta-Duzenle" />
     }
    else if (deletelokantadatastatus) {
         PageRedirect = <Redirect to="/lokanta-sil" />
     }
    else if (lokantalockedstatus) {
        PageRedirect = <Redirect to="/lokanta-engellemek" />
    }
     else if (lokantasifirlamakstatus) {
        PageRedirect = <Redirect to="/lokanta-sifre-sifirlama" />
     }

    return (
        <div>
            {PageRedirect}
            <Header />
            <div class="wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box">
                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Aninda Bi'Kurye</a></li>

                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Lokanta</a></li>
                                        <li class="breadcrumb-item active">Lokanta Listesi</li>
                                    </ol>
                                </div>
                                <h4 class="page-title">Lokanta Listesi</h4>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-box">
                                <div class="dropdown float-right">

                                </div>
                                <Link to='/yenilokanta' style={{ cursor: 'pointer' }} >
                                    <h4 class="mt-0 header-title"> <i class="ti-plus" style={{ color: 'green', fontSize: 18, fontWeight: 'bold', cursor: 'pointer' }}></i>Yeni Lokanta Kaydet </h4>
                                </Link>
                                <p class="text-muted font-14 mb-3" style={{ marginTop: 20 }}>

                                    <input className="form-control" value={name} onChange={handleName} type="text" placeholder="Lokanta Ara" />
                                    {/* {
                                        decoded.userdata[0].email === "izmir@anindabikurye.com" ? null : <>

                                            <h3>Sehir sec : </h3>
                                            <select value={sehir} onChange={handleSehir} class="form-control">
                                                <option value="Tum">Sehir seç</option>
                                                <option value="Izmir">Izmir</option>
                                                <option value="Istanbul">Istanbul</option>
                                            </select>
                                        </>
                                    } */}


                                </p>
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <thead>
                                            <tr>

                                                <th>Logo</th>
                                                <th>Adi</th>
                                                <th>Sahibi</th>
                                                <th>Telefon</th>
                                                <th>Zaman</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                loading ? <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th> <Loader /></th>
                                                    <th></th>
                                                </tr>
                                                    :
                                                    LokantaMap
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};
export default LokantaList;