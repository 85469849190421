import React, { useEffect, useState } from 'react';
import Decode from 'jwt-decode';

// IMPORT DEPENDENCIES
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addCicekService, uploadFileService } from '../../actions/admin/admin';

//IMPORT COMPONENT
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Loader from '../../components/loader/loader';

const YeniCicek = () => {

    const Dispacth = useDispatch();

    const token = useSelector(state => state.AuthReducer.token);
    const loading = useSelector(state => state.AdminReducer.loading);
    const loadingupload = useSelector(state => state.AdminReducer.loadingupload);
    const logolokanta = useSelector(state => state.AdminReducer.logolokanta);
    const addcicekstatus = useSelector(state => state.AdminReducer.addcicekstatus);

    const [cicekAdi, setCicekAdi] = useState('');
    const [sahibiAdi, setSahibiAdi] = useState('');
    const [email, setEmail] = useState('');
    const [sahibiNumber, setSahibiNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(false);

    const handleLokantaLogo = (event) => {
       
        let files = event.target.files[0]

        if (files.type === 'image/png' || files.type === 'image/jpeg' || files.type === 'image/jpg') {

            if (files.size <= 2000000) {

                Dispacth(uploadFileService(event.target.files[0]))
                setError(false);
            }
            else {
                console.log(files.type)
                setError(true);
            }

        }
        else {
            console.log(files.type)
            setError(true);
        }

    }


    const handleCicekAdi = (e) => {
        setCicekAdi(e.target.value);
        setError(false);
    }
    const handleSahibiAdi = (e) => {
        setSahibiAdi(e.target.value);
        setError(false);
    }
    const handleEmail = (e) => {
        setEmail(e.target.value);
        setError(false);
    }
    const handleSahibiNumber = (e) => {
        setSahibiNumber(e.target.value);
        setError(false);
    }
    const handlePassword = (e) => {
        setPassword(e.target.value);
        setError(false);
    }
    const handleConfirmassword = (e) => {
        setConfirmPassword(e.target.value);
        setError(false);
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (cicekAdi !== ''   && cicekAdi.trim() !== ''  && sahibiAdi !== '' && sahibiAdi.trim() !== '' && email !== ''
            && email.trim() !== '' && sahibiNumber !== '' && sahibiNumber.trim() !== '' && password !== '' && password.trim() !== '' && password === confirmPassword && logolokanta !== null) {
            Dispacth(addCicekService(cicekAdi, sahibiAdi, sahibiNumber, email, logolokanta, password, token));
        }
        else {
            setError(true);
        }
    }


    let PageRedirect = null
    if (addcicekstatus) {
        PageRedirect = <Redirect to="/cicek-list" />
    }

    return (
        <div>
            <Header />
            <div class="wrapper">
                <div class="container-fluid">
                    {PageRedirect}
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box">
                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Aninda Bi'Kurye</a></li>
                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Yeni Cicek Kaydet</a></li>
                                        <li class="breadcrumb-item active">Yeni Cicek Kaydet</li>
                                    </ol>
                                </div>
                                <h4 class="page-title"></h4>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="card-box">
                                <h4 class="m-t-0 header-title">Yeni Cicek Kaydet</h4>
                                <p class="text-muted mb-4 font-14">
                                </p>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="p-2">
                                            <form class="form-horizontal" role="form" onSubmit={handleSubmit} >
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Cicek Adi</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={cicekAdi} class="form-control" onChange={handleCicekAdi} placeholder="cicek adı" />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Cicek Logo</label>
                                                    {loadingupload ?
                                                        <div class="col-sm-10">
                                                            <Loader />
                                                        </div>
                                                        :
                                                        logolokanta !== null ?
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">{logolokanta}</label>
                                                            :
                                                            <div class="col-sm-10">
                                                                <input type="file" name="file" class="form-control" onChange={handleLokantaLogo} placeholder="cicek logo" />
                                                            </div>
                                                    }

                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Sahibi Adi</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={sahibiAdi} class="form-control" onChange={handleSahibiAdi} placeholder="Sahibi Adi soyadi" />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Sahibi Numara</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={sahibiNumber} class="form-control" onChange={handleSahibiNumber} placeholder='cicekci Numara' />
                                                    </div>
                                                </div>
                                                {/* <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Telefon</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={tel} class="form-control" onChange={handleTel} placeholder='05...' />
                                                    </div>
                                                </div> */}
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">E-Posta</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={email} class="form-control" onChange={handleEmail} placeholder='cicek E-Posta' />
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Şifre</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={password} class="form-control" onChange={handlePassword} placeholder='cicek Şifre' />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Şifre(Tekrar)</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={confirmPassword} class="form-control" onChange={handleConfirmassword} placeholder='cicek Şifre Tekrar' />
                                                    </div>
                                                </div>
                                                {
                                                    error ?
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput"></label>
                                                            <div class="col-sm-10">
                                                                <p style={{ color: 'red' }}>Bilgilerinizi kontrol edin lütfen!</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput"></label>
                                                    {loading ? <div class="col-sm-10">
                                                        <Loader />
                                                    </div>
                                                        :
                                                        <div class="col-sm-10">
                                                            <button type="submit" class="btn btn-primary">Kaydet</button>
                                                            <Link to='/cicek-list' class="btn btn-success" style={{ marginLeft: 10, color: 'white' }}>Geri don</Link>
                                                        </div>
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default YeniCicek