import React, { useEffect, useState } from 'react';
import Decode from 'jwt-decode';

// IMPORT DEPENDENCIES
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addKuryeService } from '../../actions/admin/admin';

//IMPORT COMPONENT
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Loader from '../../components/loader/loader';

const AddKurye = () => {

    const Dispacth = useDispatch();

    const token = useSelector(state => state.AuthReducer.token);
    const addkuryestatus = useSelector(state => state.AdminReducer.addkuryestatus);
    const loading = useSelector(state => state.AdminReducer.loading);

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [cuzdan, setCuzdan] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [sehir, setSehir] = useState('null')
    const [error, setError] = useState(false);

    var decoded = Decode(token)

    const handleCuzdan = (e) => {
        setCuzdan(e.target.value)
        setError(false);
    }
    const handleName = (e) => {
        setName(e.target.value);
        setError(false);
    }
    const handleSurname = (e) => {
        setSurname(e.target.value);
        setError(false);
    }
    const handleEmail = (e) => {
        setEmail(e.target.value);
        setError(false);
    }
    const handleTel = (e) => {
        setTel(e.target.value);
        setError(false);
    }
    const handlePassword = (e) => {
        setPassword(e.target.value);
        setError(false);
    }
    const handleConfirmassword = (e) => {
        setConfirmPassword(e.target.value);
        setError(false);
    }

    const handleSehir = (e) => {
        setSehir(e.target.value)
        console.log(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name !== '' && surname !== 0 && name.trim() !== '' && surname.trim() !== '' && cuzdan !== '' && cuzdan.trim() !== '' && email !== ''
            && email.trim() !== '' && tel !== '' && tel.trim() !== '' && password !== '' && password.trim() !== '' && password == confirmPassword && sehir !== 'null') {
            Dispacth(addKuryeService(name, surname, cuzdan, email, tel, password, sehir, token));
        }
        else {
            setError(true);
        }
    }


    const handleSubmitIzmir = (e) => {
        e.preventDefault();
        if (name !== '' && surname !== 0 && name.trim() !== '' && surname.trim() !== '' && cuzdan !== '' && cuzdan.trim() !== '' && email !== ''
            && email.trim() !== '' && tel !== '' && tel.trim() !== '' && password !== '' && password.trim() !== '' && password == confirmPassword) {
            Dispacth(addKuryeService(name, surname, cuzdan, email, tel, password, "Izmir", token));
        }
        else {
            setError(true);
        }
    }


    let PageRedirect = null
    if (addkuryestatus) {
        PageRedirect = <Redirect to="/kurye-listesi" />
    }

    return (
        <div>
            <Header />
            <div class="wrapper">
                <div class="container-fluid">
                    {PageRedirect}
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box">
                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Aninda Bi'Kurye</a></li>
                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Yeni Kurye Kaydet</a></li>
                                        <li class="breadcrumb-item active">Yeni Kurye Kaydet</li>
                                    </ol>
                                </div>
                                <h4 class="page-title"></h4>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="card-box">
                                <h4 class="m-t-0 header-title">AYeni Kurye Kaydet</h4>
                                <p class="text-muted mb-4 font-14">
                                </p>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="p-2">
                                            <form class="form-horizontal" role="form" onSubmit={ decoded.userdata[0].email === "izmir@anindabikurye.com" ? handleSubmitIzmir :  handleSubmit} >
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Adi</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={name} class="form-control" onChange={handleName} placeholder="kurye adı" />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Soyadi</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={surname} class="form-control" onChange={handleSurname} placeholder="kurye soyadı" />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Cuzdan</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={cuzdan} class="form-control" onChange={handleCuzdan} placeholder='Kurye Cuzdan' />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Telefon</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={tel} class="form-control" onChange={handleTel} placeholder='05...' />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">E-Posta</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={email} class="form-control" onChange={handleEmail} placeholder='Kurye E-Posta' />
                                                    </div>
                                                </div>

                                                {
                                                    decoded.userdata[0].email === "izmir@anindabikurye.com" ? null :
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">Sehir</label>
                                                            <div class="col-sm-10">
                                                                <select value={sehir} onChange={handleSehir} class="form-control">
                                                                    <option value="null">Sehir seç</option>
                                                                    <option value="Izmir">Izmir</option>
                                                                    <option value="Istanbul">Istanbul</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                }

                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Şifre</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={password} class="form-control" onChange={handlePassword} placeholder='Kurye Şifre' />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Şifre(Tekrar)</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={confirmPassword} class="form-control" onChange={handleConfirmassword} placeholder='Kurye Şifre Tekrar' />
                                                    </div>
                                                </div>
                                                {
                                                    error ?
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput"></label>
                                                            <div class="col-sm-10">
                                                                <p style={{ color: 'red' }}>Bilgilerinizi kontrol edin lütfen!</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput"></label>
                                                    {loading ? <div class="col-sm-10">
                                                        <Loader />
                                                    </div>
                                                        :
                                                        <div class="col-sm-10">
                                                            <button type="submit" class="btn btn-primary">Kaydet</button>
                                                            <Link to='/kurye-listesi' class="btn btn-success" style={{ marginLeft: 10, color: 'white' }}>Geri don</Link>
                                                        </div>
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default AddKurye