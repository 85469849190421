import React, { useEffect, useState } from 'react';
import Decode from 'jwt-decode';

// IMPORT DEPENDENCIES
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSiparisService } from '../../actions/admin/admin';
import Moment from 'react-moment';
import 'moment/locale/tr';

//IMPORT COMPONENT
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Loader from '../../components/loader/loader';

const UserList = (props) => {

    const Dispatch = useDispatch();
    const token = useSelector(state => state.AuthReducer.token);
    const loading = useSelector(state => state.AdminReducer.loading);
    const siparislist = useSelector(state => state.AdminReducer.siparislist);


    var decoded = Decode(token)

    // const [userName, setUserName] = useState('')

    useEffect(() => {
        Dispatch(getSiparisService(token));
    }, []);


    // const handleUserName = (e) => {
    //     setUserName(e.target.value)
    //     Dispatch(searchUserService(e.target.value, token))
    // }


    let SiparisMap = siparislist.map((item, index) => {
        return (
            <tr key={index}>

                <td>{item.startaddress}</td>
                <td>{item.endaddress}</td>
                <td>{item.distance} KM</td>
                <td>{item.price} TL</td>
                <td>{item.status === 0 ? 'Kimse Daha Almadi' : item.status === 1 ?
                    'Alinacak Yer gidiyor' : item.status === 2 ? 'Paket almis - Verilecek yer gidiyor' : item.status === 3
                        ? 'Testlim Etti' : item.status === 4 ? 'Iptal Oldu' : ' Hata Oldu'}</td>
                <td> <Moment fromNow>{item.createdAt}</Moment> </td>
                {/* <td>
                    <i onClick={() => updateKurye(item)} class="ti-pencil" style={{ color: 'green', fontSize: 18, fontWeight: 'bold', cursor: 'pointer' }}></i>
                    <i onClick={() => deleteKurye(item)} class="ti-trash" style={{ color: 'red', fontSize: 18, marginLeft: 15, cursor: 'pointer' }}></i>
                    <i onClick={() => kuryeParasi(item)} class="ti-money" style={{ color: 'blue', fontSize: 18, marginLeft: 15, cursor: 'pointer' }}></i>
                    <i onClick={() => sifirlamaSifre(item)} class="ti-user" style={{ color: 'orange', fontSize: 18, marginLeft: 15, cursor: 'pointer' }}></i>
                </td> */}
            </tr>
        )
    })

    let PageRedirect = null;

    return (
        <div>
            {PageRedirect}
            <Header />
            <div class="wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box">
                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Aninda Bi'Kurye</a></li>

                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Siparisler</a></li>
                                        <li class="breadcrumb-item active">Siparisler Listesi</li>
                                    </ol>
                                </div>
                                <h4 class="page-title">Siparisler Listesi</h4>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-box">
                                <div class="dropdown float-right">

                                </div>
                                <Link to='/' style={{ cursor: 'pointer' }} >
                                    <h4 class="mt-0 header-title"> <i class="ti-plus" style={{ color: 'green', fontSize: 18, fontWeight: 'bold', cursor: 'pointer' }}></i>Yeni Siparis Ver </h4>
                                </Link>
                                {/* <p class="text-muted font-14 mb-3" style={{ marginTop: 20 }}>
                                    <input className="form-control" value={userName} onChange={handleUserName} type="text" placeholder="Kurye Ara" />
                                  
                                </p> */}
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <thead>
                                            <tr>

                                                <th>Alinacak Yer</th>
                                                <th>Verilecek Yer</th>
                                                <th>Mesafe</th>
                                                <th>Fiyat</th>
                                                <th>Durumu</th>
                                                <td>Zaman</td>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                loading ? <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th> <Loader /></th>
                                                    <th></th>
                                                </tr>
                                                    :
                                                    SiparisMap
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};
export default UserList;