import React, { useEffect, useState } from 'react';
import Decode from 'jwt-decode';

// IMPORT DEPENDENCIES
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateKuryeService, updateKuryeDataCancel } from '../../actions/admin/admin';

//IMPORT COMPONENT
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Loader from '../../components/loader/loader';

const UpdateKurye = () => {

    const Dispatch = useDispatch();

    

    const token = useSelector(state => state.AuthReducer.token);
    const updatekuryestatus = useSelector(state => state.AdminReducer.updatekuryestatus);
    const kuryeupdatedata = useSelector(state => state.AdminReducer.kuryeupdatedata);
    const loading = useSelector(state => state.AdminReducer.loading);


    var decoded = Decode(token)

    useEffect(() => {
        Dispatch(updateKuryeDataCancel());
    }, []);

    const [name, setName] = useState(kuryeupdatedata.name);
    const [surname, setSurname] = useState(kuryeupdatedata.surname);
    const [cuzdan, setCuzdan] = useState(kuryeupdatedata.cuzdan);
    const [tel, setTel] = useState(kuryeupdatedata.telephone);
    const [sehir,setSehir]=useState(kuryeupdatedata.sehir)
    const [error, setError] = useState(false);

    const handleCuzdan = (e) => {
        setCuzdan(e.target.value)
        setError(false);
    }
    const handleName = (e) => {
        setName(e.target.value);
        setError(false);
    }
    const handleSurname = (e) => {
        setSurname(e.target.value);
        setError(false);
    }
    const handleTel = (e) => {
        setTel(e.target.value);
        setError(false);
    }

    const handleSehir = (e) => {
        setSehir(e.target.value)
        setError(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name !== '' && surname !== 0 && name.trim() !== '' && surname.trim() !== '' && cuzdan !== '' && cuzdan.trim() !== ''  && tel !== '' && tel.trim() !== '' && sehir!=='null') {
            Dispatch(updateKuryeService(name,surname, cuzdan, tel, kuryeupdatedata.id , sehir, token));
        }
        else {
            setError(true);
        }
    }


    const handleSubmitIzmir = (e) => {
        e.preventDefault();
        if (name !== '' && surname !== 0 && name.trim() !== '' && surname.trim() !== '' && cuzdan !== '' && cuzdan.trim() !== ''  && tel !== '' && tel.trim() !== '' && sehir!=='null') {
            Dispatch(updateKuryeService(name,surname, cuzdan, tel, kuryeupdatedata.id , "Izmir", token));
        }
        else {
            setError(true);
        }
    }

    let PageRedirect = null
    if (updatekuryestatus) {
        PageRedirect = <Redirect to="/kurye-listesi" />
    }

    return (
        <div>
            <Header />
            <div class="wrapper">
                <div class="container-fluid">
                    {PageRedirect}
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box">
                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Aninda Bi'Kurye</a></li>
                                        <li class="breadcrumb-item"><a href="javascript: void(0);"> Kurye Düzenle</a></li>
                                        <li class="breadcrumb-item active"> Kurye Düzenle</li>
                                    </ol>
                                </div>
                                <h4 class="page-title"></h4>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="card-box">
                                <h4 class="m-t-0 header-title"> Kurye Düzenle</h4>
                                <p class="text-muted mb-4 font-14">
                                </p>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="p-2">
                                            <form class="form-horizontal" role="form" onSubmit={ decoded.userdata[0].email === "izmir@anindabikurye.com" ? handleSubmitIzmir :  handleSubmit} >
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Adi</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={name} class="form-control" onChange={handleName} placeholder="kurye adı" />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Soyadi</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={surname} class="form-control" onChange={handleSurname} placeholder="kurye soyadı" />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Cuzdan</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={cuzdan} class="form-control" placeholder='Kurye Cuzdan' disabled />
                                                    </div>
                                                </div>
                                                {
                                                    decoded.userdata[0].email === "izmir@anindabikurye.com" ? null :
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput">Sehir</label>
                                                            <div class="col-sm-10">
                                                                <select value={sehir} onChange={handleSehir} class="form-control">
                                                                    <option value="null">Sehir seç</option>
                                                                    <option value="Izmir">Izmir</option>
                                                                    <option value="Istanbul">Istanbul</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                }
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput">Telefon</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" id="simpleinput" value={tel} class="form-control" onChange={handleTel} placeholder='05...' />
                                                    </div>
                                                </div>
                                                {
                                                    error ?
                                                        <div class="form-group row">
                                                            <label class="col-sm-2  col-form-label" for="simpleinput"></label>
                                                            <div class="col-sm-10">
                                                                <p style={{ color: 'red' }}>Bilgilerinizi kontrol edin lütfen!</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <div class="form-group row">
                                                    <label class="col-sm-2  col-form-label" for="simpleinput"></label>
                                                    {loading ? <div class="col-sm-10">
                                                        <Loader />
                                                    </div>
                                                        :
                                                        <div class="col-sm-10">
                                                            <button type="submit" class="btn btn-primary">Düzenle</button>
                                                            <Link to='/kurye-listesi' class="btn btn-success" style={{ marginLeft: 10, color: 'white' }}>Geri don</Link>
                                                        </div>
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default UpdateKurye