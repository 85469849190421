import React, { useEffect, useState } from 'react';
import Decode from 'jwt-decode';

// IMPORT DEPENDENCIES
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getKuryeService, updateKuryeData, deleteKuryeData, getKuryeCityService, searchKuryeService, updateKuryeParasi, updateSifreSifirlama } from '../../actions/admin/admin';
import Moment from 'react-moment';
import 'moment/locale/tr';

//IMPORT COMPONENT
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Loader from '../../components/loader/loader';

const KuryeList = (props) => {

    const Dispatch = useDispatch();
    const token = useSelector(state => state.AuthReducer.token);
    const loading = useSelector(state => state.AdminReducer.loading);
    const kuryelist = useSelector(state => state.AdminReducer.kuryelist);
    const kuryeupdatedatastatus = useSelector(state => state.AdminReducer.kuryeupdatedatastatus);
    const kuryedeletedatastatus = useSelector(state => state.AdminReducer.kuryedeletedatastatus);
    const kuryeparasistatus = useSelector(state => state.AdminReducer.kuryeparasistatus);
    const sifresifirlamastatus = useSelector(state => state.AdminReducer.sifresifirlamastatus);
    
    const username = useSelector(state => state.AuthReducer.username);


    const [sehir, setSehir] = useState('Tum')
    const [kuryeName, setKuryeName] = useState('')

    var decoded = Decode(token)

    useEffect(() => {
        if (decoded.userdata[0].email === "izmir@anindabikurye.com") {
            Dispatch(getKuryeCityService("Izmir", token));
        }
        else {
            Dispatch(getKuryeService(token));
        }

    }, []);

    const deleteKurye = (item) => {
        Dispatch(deleteKuryeData(item))
    }

    const updateKurye = (item) => {
        Dispatch(updateKuryeData(item))
    }

    const kuryeParasi = (item) => {
        Dispatch(updateKuryeParasi(item))
    }

    const sifirlamaSifre = (item) => {
        Dispatch(updateSifreSifirlama(item))
    }

    const handleSehir = (e) => {
        setSehir(e.target.value)

        if (e.target.value === "Tum") {
            Dispatch(getKuryeService(token));
        }
        else {
            Dispatch(getKuryeCityService(e.target.value, token));
        }
    }

    const handleKuryeName = (e) => {
        setKuryeName(e.target.value)
        Dispatch(searchKuryeService(e.target.value, token))
    }

    let KuryeMap = kuryelist.map((item, index) => {
        return (
            <tr key={index}>

                <td>{item.name}</td>
                <td>{item.surname}</td>
                <td>{item.cuzdan} TL</td>
                <td>{item.sehir}</td>
                <td> <Moment fromNow>{item.createdAt}</Moment> </td>
                <td>
                    <i onClick={() => updateKurye(item)} class="ti-pencil" style={{ color: 'green', fontSize: 18, fontWeight: 'bold', cursor: 'pointer' }}></i>
                    <i onClick={() => deleteKurye(item)} class="ti-trash" style={{ color: 'red', fontSize: 18, marginLeft: 15, cursor: 'pointer' }}></i>
                    <i onClick={() => kuryeParasi(item)} class="ti-money" style={{ color: 'blue', fontSize: 18, marginLeft: 15, cursor: 'pointer' }}></i>
                    <i onClick={() => sifirlamaSifre(item)} class="ti-user" style={{ color: 'orange', fontSize: 18, marginLeft: 15, cursor: 'pointer' }}></i>
                </td>
            </tr>
        )
    })

    let PageRedirect = null;

    if (kuryeupdatedatastatus) {
        PageRedirect = <Redirect to="/kurye-Duzenle" />
    }
    else if (kuryedeletedatastatus) {
        PageRedirect = <Redirect to="/kurye-Sil" />
    }
    else if (kuryeparasistatus) {
        PageRedirect = <Redirect to="/kurye-parasi" />
    }
    else if (sifresifirlamastatus) {
        PageRedirect = <Redirect to="/kurye-sifre-sifirlama" />
    }

    return (
        <div>
            {PageRedirect}
            <Header />
            <div class="wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box">
                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Aninda Bi'Kurye</a></li>

                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Kuryeler</a></li>
                                        <li class="breadcrumb-item active">Kuryeler Listesi</li>
                                    </ol>
                                </div>
                                <h4 class="page-title">Kuryeler Listesi</h4>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-box">
                                <div class="dropdown float-right">

                                </div>
                                <Link to='/yeni-kurye-kaydet' style={{ cursor: 'pointer' }} >
                                    <h4 class="mt-0 header-title"> <i class="ti-plus" style={{ color: 'green', fontSize: 18, fontWeight: 'bold', cursor: 'pointer' }}></i>Yeni Kurye Kaydet </h4>
                                </Link>
                                <p class="text-muted font-14 mb-3" style={{ marginTop: 20 }}>

                                    <input className="form-control" value={kuryeName} onChange={handleKuryeName} type="text" placeholder="Kurye Ara" />
                                    {/* {
                                        decoded.userdata[0].email === "izmir@anindabikurye.com" ? null : <>

                                            <h3>Sehir sec : </h3>
                                            <select value={sehir} onChange={handleSehir} class="form-control">
                                                <option value="Tum">Sehir seç</option>
                                                <option value="Izmir">Izmir</option>
                                                <option value="Istanbul">Istanbul</option>
                                            </select>
                                        </>
                                    } */}


                                </p>
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <thead>
                                            <tr>

                                                <th>Adi</th>
                                                <th>Soyadi</th>
                                                <th>Cuzdan</th>
                                                <th>Sehir</th>
                                                <th>Kayit</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                loading ? <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th> <Loader /></th>
                                                    <th></th>
                                                </tr>
                                                    :
                                                    KuryeMap
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};
export default KuryeList;