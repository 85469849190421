import React from 'react'


const Footer = () => {
    return(

        <footer class="footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6">
                         2021 &copy; Admin Panel by <a href="http://angeboutchoue.com/" style={{color:'#3c3333'}}> Ange Boutchoue</a>
                    </div>
                    <div class="col-md-6">
                        <div class="text-md-right footer-links d-none d-sm-block">
                            <a href="javascript:void(0);">About Us</a>
                            <a href="javascript:void(0);">Help</a>
                            <a href="javascript:void(0);">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer