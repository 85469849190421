import React, { useState } from 'react';
// IMPORT DEPENDENCIES
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginService } from '../../actions/auth/auth';

// IMPORT COMPONENT
import Loader from '../../components/loader/loader';

const Login = () => {

    const Dispacth = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const token = useSelector(state => state.AuthReducer.token);
    const role = useSelector(state => state.AuthReducer.role);
    const loading = useSelector(state => state.AuthReducer.loading);
    const errorserver = useSelector(state => state.AuthReducer.error);

    const handleEmail = (e) => {
        setError(false)
        setEmail(e.target.value)
    }

    const handlePassword = (e) => {
        setError(false)
        setPassword(e.target.value)
    }

    const submitHandler = (e) => {
        e.preventDefault();
        if (email !== null && email.trim() !== null && password !== null && password.trim() !== null && email !== '' && password !== '') {
            Dispacth(loginService(email, password))
        }
        else {
            setError(true)
        }
    }

    let authRedirect = null;
    if (token && (role === 'admin')) {
        authRedirect = <Redirect to="/" />
    }

    return (
        <div className="authentication-bg vh-100">
            {authRedirect}
            <div className="account-pages">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="text-center">
                                <a href="index.html">
                                    <span><img src="assets/images/logo-dark.png" alt="" height="22" /></span>
                                </a>
                            </div>
                            <div className="card">
                                <div className="card-body p-4">
                                    <div className="text-center mb-4">
                                        <h4 className="text-uppercase mt-0">Giriş Yapmak</h4>
                                    </div>
                                    <form onSubmit={submitHandler} >
                                        <div className="form-group mb-3">
                                            <label htmlFor="emailaddress">E-posta</label>
                                            <input className="form-control" type="email" id="emailaddress" name="email" value={email} onChange={handleEmail} placeholder="E-postanızı giriniz" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="password">Şifre</label>
                                            <input className="form-control" type="password" id="password" name="password" value={password} onChange={handlePassword} placeholder="Şifrenizi girin" />
                                        </div>
                                        <div className="form-group mb-0 text-center">
                                            {loading ? <Loader /> : <button className="btn btn-primary btn-block" type="submit"> Giriş </button>}
                                        </div>
                                        {
                                            error ? <div>
                                                <br />
                                                <span style={{ marginTop: '100px', textAlign: 'center', color: 'red' }}>Bilgilerinizi kontrol edin lütfen!</span>
                                            </div>
                                                :
                                                null
                                        }

                                        {
                                            errorserver ? <div>
                                                <br />
                                                <span style={{ marginTop: '100px', textAlign: 'center', color: 'red' }}>{errorserver}</span>
                                            </div>
                                                :
                                                null
                                        }
                                        <div class="row mt-3">
                                            <div class="col-12 text-center">
                                                <i className="fa fa-lock mr-1"></i>
                                                <span>Parolanızı mı unuttunuz?</span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login;