
export const GET_KURYE_START = "GET_KURYE_START";
export const GET_KURYE_SUCCESS = "GET_KURYE_SUCCESS";
export const GET_KURYE_FAILED = "GET_KURYE_FAILED";

export const ADD_KURYE_START = "ADD_KURYE_START";
export const ADD_KURYE_SUCCESS = "ADD_KURYE_SUCCESS";
export const ADD_KURYE_FAILED = "ADD_KURYE_FAILED";

export const UPDATE_KURYE_DATA = "UPDATE_KURYE_DATA";
export const UPDATE_KURYE_DATA_CANCEL = "UPDATE_KURYE_DATA_CANCEL";
export const UPDATE_KURYE_START = "UPDATE_KURYE_START";
export const UPDATE_KURYE_SUCCESS = "UPDATE_KURYE_SUCCESS";
export const UPDATE_KURYE_FAILED = "UPDATE_KURYE_FAILED";

export const DELETE_KURYE_DATA = "DELETE_KURYE_DATA";
export const DELETE_KURYE_DATA_CANCEL = "DELETE_KURYE_DATA_CANCEL";
export const DELETE_KURYE_START = "DELETE_KURYE_START";
export const DELETE_KURYE_SUCCESS = "DELETE_KURYE_SUCCESS";
export const DELETE_KURYE_FAILED = "DELETE_KURYE_FAILED";

export const GET_KURYE_CITY_START = "GET_KURYE_CITY_START";
export const GET_KURYE_CITY_SUCCESS = "GET_KURYE_CITY_SUCCESS";
export const GET_KURYE_CITY_FAILED = "GET_KURYE_CITY_FAILED";

export const SEARCH_KURYE_START = "SEARCH_KURYE_START";
export const SEARCH_KURYE_SUCCESS = "SEARCH_KURYE_SUCCESS";
export const SEARCH_KURYE_FAILED = "SEARCH_KURYE_FAILED";

export const UPDATE_KURYE_PARASI = "UPDATE_KURYE_PARASI";
export const UPDATE_KURYE_PARASI_CANCEL = "UPDATE_KURYE_PARASI_CANCEL";

export const KURYE_PARASI_START = "KURYE_PARASI_START";
export const KURYE_PARASI_SUCCESS = "KURYE_PARASI_SUCCESS";
export const KURYE_PARASI_FAILED = "KURYE_PARASI_FAILED";

export const UPDATE_SIFRE_SIFIRLAMA = "UPDATE_SIFRE_SIFIRLAMA";
export const UPDATE_SIFRE_SIFIRLAMA_CANCEL = "UPDATE_SIFRE_SIFIRLAMA_CANCEL";

export const GET_KURYE_USER_INFO_START = "GET_KURYE_USER_INFO_START";
export const GET_KURYE_USER_INFO_SUCCESS = "GET_KURYE_USER_INFO_SUCCESS";
export const GET_KURYE_USER_INFO_FAILED = "GET_KURYE_USER_INFO_FAILED";

export const SIFRE_SIFIRLA_START = "SIFRE_SIFIRLA_START";
export const SIFRE_SIFIRLA_SUCCESS = "SIFRE_SIFIRLA_SUCCESS";
export const SIFRE_SIFIRLA_FAILED = "SIFRE_SIFIRLA_FAILED";

export const GET_APP_INFO_START = "GET_APP_INFO_START";
export const GET_APP_INFO_SUCCESS = "GET_APP_INFO_SUCCESS";
export const GET_APP_INFO_FAILED = "GET_APP_INFO_FAILED";

export const GET_USER_START = "GET_USER_START";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";

export const SEARCH_USER_START = "SEARCH_USER_START";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
export const SEARCH_USER_FAILED = "SEARCH_USER_FAILED";

export const GET_SIPARIS_START = "GET_SIPARIS_START";
export const GET_SIPARIS_SUCCESS = "GET_SIPARIS_SUCCESS";
export const GET_SIPARIS_FAILED = "GET_SIPARIS_FAILED";

export const GET_APP_SETTINGS_START = "GET_APP_SETTINGS_START";
export const GET_APP_SETTINGS_SUCCESS = "GET_APP_SETTINGS_SUCCESS";
export const GET_APP_SETTINGS_FAILED = "GET_APP_SETTINGS_FAILED";

export const HIZMET_BEDELI_MK = "HIZMET_BEDELI_MK";
export const HIZMET_BEDELI_AC = "HIZMET_BEDELI_AC";
export const KM_BASI_MK = "KM_BASI_MK";
export const KM_BASI_AC = "KM_BASI_AC";
export const MK_1_5 = "MK_1_5";
export const MK_6_11 = "MK_6_11";
export const MK_12_18 = "MK_12_18";
export const AC_1_5 = "AC_1_5";
export const AC_6_11 = "AC_6_11";
export const AC_12_18 = "AC_12_18";
export const LOKANTA_SWITCH = "LOKANTA_SWITCH";
export const CICEK_SWITCH = "CICEK_SWITCH";
export const API_KEY = "API_KEY";
export const DESTEK_NUMARA = "DESTEK_NUMARA";
export const YUZDE = "YUZDE";
export const TOPLUDAGITIM = "TOPLUDAGITIM";

export const UPDATE_APP_SETTINGS_START = "UPDATE_APP_SETTINGS_START";
export const UPDATE_APP_SETTINGS_SUCCESS = "UPDATE_APP_SETTINGS_SUCCESS";
export const UPDATE_APP_SETTINGS_FAILED = "UPDATE_APP_SETTINGS_FAILED";

export const ADD_LOKANTA_START = "ADD_LOKANTA_START";
export const ADD_LOKANTA_SUCCESS = "ADD_LOKANTA_SUCCESS";
export const ADD_LOKANTA_FAILED = "ADD_LOKANTA_FAILED";

export const UPLOAD_FILE_START = "UPLOAD_FILE_START";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILED = "UPLOAD_FILE_FAILED";

export const GET_LOKANTA_START = "GET_LOKANTA_START";
export const GET_LOKANTA_SUCCESS = "GET_LOKANTA_SUCCESS";
export const GET_LOKANTA_FAILED = "GET_LOKANTA_FAILED";

export const SEARCH_LOKANTA_START = "SEARCH_LOKANTA_START";
export const SEARCH_LOKANTA_SUCCESS = "SEARCH_LOKANTA_SUCCESS";
export const SEARCH_LOKANTA_FAILED = "SEARCH_LOKANTA_FAILED";

export const UPDATE_LOKANTA_DATA = "UPDATE_LOKANTA_DATA";
export const UPDATE_LOKANTA_DATA_CANCEL = "UPDATE_LOKANTA_DATA_CANCEL";
export const UPDATE_LOKANTA_START = "UPDATE_LOKANTA_START";
export const UPDATE_LOKANTA_SUCCESS = "UPDATE_LOKANTA_SUCCESS";
export const UPDATE_LOKANTA_FAILED = "UPDATE_LOKANTA_FAILED";

export const DELETE_LOKANTA_DATA = "DELETE_LOKANTA_DATA";
export const DELETE_LOKANTA_DATA_CANCEL = "DELETE_LOKANTA_DATA_CANCEL";
export const DELETE_LOKANTA_START = "DELETE_LOKANTA_START";
export const DELETE_LOKANTA_SUCCESS = "DELETE_LOKANTA_SUCCESS";
export const DELETE_LOKANTA_FAILED = "DELETE_LOKANTA_FAILED";

export const GET_LOKANTA_USER_INFO_START = "GET_LOKANTA_USER_INFO_START";
export const GET_LOKANTA_USER_INFO_SUCCESS = "GET_LOKANTA_USER_INFO_SUCCESS";
export const GET_LOKANTA_USER_INFO_FAILED = "GET_LOKANTA_USER_INFO_FAILED";

export const LOCKED_LOKANTA = "LOCKED_LOKANTA";
export const SIFIRLAMA_LOKANTA = "SIFIRLAMA_LOKANTA";

export const LOKANTA_LOCKED_START = "LOKANTA_LOCKED_START";
export const LOKANTA_LOCKED_SUCCESS = "LOKANTA_LOCKED_SUCCESS";
export const LOKANTA_LOCKED_FAILED = "LOKANTA_LOCKED_FAILED";

export const LOKANTA_SIFIRLA_START = "LOKANTA_SIFIRLA_START";
export const LOKANTA_SIFIRLA_SUCCESS = "LOKANTA_SIFIRLA_SUCCESS";
export const LOKANTA_SIFIRLA_FAILED = "LOKANTA_SIFIRLA_FAILED";

export const ADD_CICEK_START = "ADD_CICEK_START";
export const ADD_CICEK_SUCCESS = "ADD_CICEK_SUCCESS";
export const ADD_CICEK_FAILED = "ADD_CICEK_FAILED";

export const GET_CICEK_START = "GET_CICEK_START";
export const GET_CICEK_SUCCESS = "GET_CICEK_SUCCESS";
export const GET_CICEK_FAILED = "GET_CICEK_FAILED";

export const SEARCH_CICEK_START = "SEARCH_CICEK_START";
export const SEARCH_CICEK_SUCCESS = "SEARCH_CICEK_SUCCESS";
export const SEARCH_CICEK_FAILED = "SEARCH_CICEK_FAILED";

export const UPDATE_CICEK_DATA = "UPDATE_CICEK_DATA";
export const UPDATE_CICEK_DATA_CANCEL = "UPDATE_CICEK_DATA_CANCEL";
export const UPDATE_CICEK_START = "UPDATE_CICEK_START";
export const UPDATE_CICEK_SUCCESS = "UPDATE_CICEK_SUCCESS";
export const UPDATE_CICEK_FAILED = "UPDATE_CICEK_FAILED";

export const DELETE_CICEK_DATA = "DELETE_CICEK_DATA";
export const DELETE_CICEK_DATA_CANCEL = "DELETE_CICEK_DATA_CANCEL";
export const DELETE_CICEK_START = "DELETE_CICEK_START";
export const DELETE_CICEK_SUCCESS = "DELETE_CICEK_SUCCESS";
export const DELETE_CICEK_FAILED = "DELETE_CICEK_FAILED";

export const GET_CICEK_USER_INFO_START = "GET_CICEK_USER_INFO_START";
export const GET_CICEK_USER_INFO_SUCCESS = "GET_CICEK_USER_INFO_SUCCESS";
export const GET_CICEK_USER_INFO_FAILED = "GET_CICEK_USER_INFO_FAILED";

export const LOCKED_CICEK = "LOCKED_CICEK";
export const SIFIRLAMA_CICEK = "SIFIRLAMA_CICEK";

export const CICEK_LOCKED_START = "CICEK_LOCKED_START";
export const CICEK_LOCKED_SUCCESS = "CICEK_LOCKED_SUCCESS";
export const CICEK_LOCKED_FAILED = "CICEK_LOCKED_FAILED";

export const CICEK_SIFIRLA_START = "CICEK_SIFIRLA_START";
export const CICEK_SIFIRLA_SUCCESS = "CICEK_SIFIRLA_SUCCESS";
export const CICEK_SIFIRLA_FAILED = "CICEK_SIFIRLA_FAILED";

export const GET_SIPARIS_NEW_START = "GET_SIPARIS_NEW_START";
export const GET_SIPARIS_NEW_SUCCESS = "GET_SIPARIS_NEW_SUCCESS";
export const GET_SIPARIS_NEW_FAILED = "GET_SIPARIS_NEW_FAILED";

export const GET_SIPARIS_DETAIL = "GET_SIPARIS_DETAIL";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
