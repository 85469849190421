import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getAppInfoService } from '../../actions/admin/admin';

// IMPORT COMPONENT
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Loader from '../../components/loader/loader';

const Home = () => {

    const Dispatch = useDispatch();

    const userinfo = useSelector(state => state.AdminReducer.userinfo);
    const kuryeinfo = useSelector(state => state.AdminReducer.kuryeinfo);
    const cicekinfo = useSelector(state => state.AdminReducer.cicekinfo);
    const lokantainfo = useSelector(state => state.AdminReducer.lokantainfo);
    const loading = useSelector(state => state.AdminReducer.loading);
    const token = useSelector(state => state.AuthReducer.token);


    useEffect(() => {

        Dispatch(getAppInfoService(token));

    }, []);


    return (
        <div>
            <Header />
            <div class="wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box">
                                <h4 class="page-title">Gösterge Paneli</h4>
                            </div>
                        </div>
                    </div>
                    {
                        loading ? <>
                            <Loader />
                        </>
                            :

                            <div class="row">
                                <div class="col-xl-3 col-md-6">
                                    <div class="card-box">
                                        <div class="dropdown float-right">
                                        </div>
                                        <h4 class="header-title mt-0 mb-3">Kullanıcılar</h4>
                                        <div class="widget-box-2">
                                            <div class="widget-detail-2 text-right">
                                                <span class="badge badge-success badge-pill float-left mt-3">0% <i class="mdi mdi-trending-up"></i> </span>
                                                <h2 class="font-weight-normal mb-1"> {userinfo} </h2>
                                                <p class="text-muted mb-3"> </p>
                                            </div>
                                            <div class="progress progress-bar-alt-success progress-sm">
                                                <div class="progress-bar bg-success" role="progressbar"
                                                    aria-valuenow="77" aria-valuemin="0" aria-valuemax="100"
                                                    style={{ width: 77 + '%' }}>
                                                    <span class="sr-only">77% Complete</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-md-6">
                                    <div class="card-box">
                                        <h4 class="header-title mt-0 mb-3">Kuryeler</h4>
                                        <div class="widget-box-2">
                                            <div class="widget-detail-2 text-right">
                                                <span class="badge badge-success badge-pill float-left mt-3">0% <i class="mdi mdi-trending-up"></i> </span>
                                                <h2 class="font-weight-normal mb-1"> {kuryeinfo} </h2>
                                                <p class="text-muted mb-3"> </p>
                                            </div>
                                            <div class="progress progress-bar-alt-success progress-sm">
                                                <div class="progress-bar bg-success" role="progressbar"
                                                    aria-valuenow="77" aria-valuemin="0" aria-valuemax="100"
                                                    style={{ width: 77 + '%' }}>
                                                    <span class="sr-only">77% Complete</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-md-6">
                                    <div class="card-box">
                                        <div class="dropdown float-right">
                                        </div>
                                        <h4 class="header-title mt-0 mb-3">Lokantalar</h4>
                                        <div class="widget-box-2">
                                            <div class="widget-detail-2 text-right">
                                                <span class="badge badge-success badge-pill float-left mt-3">0% <i class="mdi mdi-trending-up"></i> </span>
                                                <h2 class="font-weight-normal mb-1"> {lokantainfo} </h2>
                                                <p class="text-muted mb-3"> </p>
                                            </div>
                                            <div class="progress progress-bar-alt-success progress-sm">
                                                <div class="progress-bar bg-success" role="progressbar"
                                                    aria-valuenow="77" aria-valuemin="0" aria-valuemax="100"
                                                    style={{ width: 77 + '%' }}>
                                                    <span class="sr-only">77% Complete</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-md-6">
                                    <div class="card-box">
                                        <div class="dropdown float-right">
                                        </div>
                                        <h4 class="header-title mt-0 mb-3">Cicekler</h4>
                                        <div class="widget-box-2">
                                            <div class="widget-detail-2 text-right">
                                                <span class="badge badge-success badge-pill float-left mt-3">0% <i class="mdi mdi-trending-up"></i> </span>
                                                <h2 class="font-weight-normal mb-1"> {cicekinfo} </h2>
                                                <p class="text-muted mb-3"> </p>
                                            </div>
                                            <div class="progress progress-bar-alt-success progress-sm">
                                                <div class="progress-bar bg-warning" role="progressbar"
                                                    aria-valuenow="77" aria-valuemin="0" aria-valuemax="100"
                                                    style={{ width: 77 + '%' }}>
                                                    <span class="sr-only">77% Complete</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    }

                    <div class="row">
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
};
export default Home;