import React, { useEffect, useState } from 'react';
import Decode from 'jwt-decode';
import Modal from 'react-modal';

// IMPORT DEPENDENCIES
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSiparisNewService , getSiparisDetail, openModal, closeModal} from '../../actions/admin/admin';
import Moment from 'react-moment';
import 'moment/locale/tr';


//IMPORT COMPONENT
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Loader from '../../components/loader/loader';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

const YeniSiparisList = (props) => {

    const Dispatch = useDispatch();
    const token = useSelector(state => state.AuthReducer.token);
    const loading = useSelector(state => state.AdminReducer.loading);
    const newsiparislist = useSelector(state => state.AdminReducer.newsiparislist);
    const siparisdetail = useSelector(state => state.AdminReducer.siparisdetail);
    const modal = useSelector(state => state.AdminReducer.modal);


    var decoded = Decode(token)

    // const [userName, setUserName] = useState('')
    // const [modalIsOpen, setIsOpen] = useState(false);
    // const [siparisDetail, setSiparisDetail] = useState(null);

    useEffect(() => {
        Dispatch(getSiparisNewService(token));
    }, []);


    // const handleUserName = (e) => {
    //     setUserName(e.target.value)
    //     Dispatch(searchUserService(e.target.value, token))
    // }

    // const openModal = () => {
    //     setIsOpen(true);
    // }

    // const closeModal = () => {
    //     setIsOpen(false)
    // }

    const handleCloseModal = () => {
        Dispatch(closeModal())
    }

    const handleModal = (item) => {
        console.log(item)
       Dispatch(getSiparisDetail(item))
    }

    let SiparisMap = newsiparislist.map((item, index) => {
        return (
            <tr key={index}>
                <td>{item.aracTipi}</td>
                <td>{item.odeme}</td>
                <td>{item.odemeYer}</td>
                <td>{item.sendItem}</td>
                <td>{item.price} TL</td>
                <td>{item.customer.name} {item.customer.surname}</td>
                <td>{item.customer.telephone}</td>
                <td>{item.status === 0 ? 'Kimse Daha Almadi' : item.status === 1 ?
                    'Alinacak Yer gidiyor' : item.status === 2 ? 'Paket almis - Verilecek yer gidiyor' : item.status === 3
                        ? 'Testlim Etti' : item.status === 4 ? 'Iptal Oldu' : ' Hata Oldu'}</td>
                        
                        
                <td>{item.kuryeId === null ? "Kimse daha almadı" : item.kurye.name + ' ' + item.kurye.surname}</td>
                <td>{item.kuryeId === null ? "Kimse daha almadı" : item.kurye.telephone}</td>
                <td>{item.kimverdi}</td>
              
                <td> <Moment fromNow>{item.createdAt}</Moment> </td>

                <td>
                    <i onClick={()=>{handleModal(item)}} class="ti-pencil" style={{ color: 'green', fontSize: 18, fontWeight: 'bold', cursor: 'pointer' }}></i>
                    {/* <i onClick={() => deleteKurye(item)} class="ti-trash" style={{ color: 'red', fontSize: 18, marginLeft: 15, cursor: 'pointer' }}></i>
                    <i onClick={() => kuryeParasi(item)} class="ti-money" style={{ color: 'blue', fontSize: 18, marginLeft: 15, cursor: 'pointer' }}></i>
                    <i onClick={() => sifirlamaSifre(item)} class="ti-user" style={{ color: 'orange', fontSize: 18, marginLeft: 15, cursor: 'pointer' }}></i> */}
                </td>
            </tr>
        )
    })

    let SiparisDetailss = null;
    if(siparisdetail.distance ==="0"){
        SiparisDetailss =  siparisdetail.newcolisaddresses.map((item, index)=>{
            return(
                <div>
                    <div>
                        <p> <span style={{fontWeight:'bold'}}>Address: </span> {index+1} - {item.address}</p>
                        <p> <span style={{fontWeight:'bold'}}>Numara: </span> {item.numara}</p>
                        <p><span style={{fontWeight:'bold'}}>Aciklama: </span>{item.aciklama}</p>
                     
                    </div>
                    <hr style={{fontWeight:'bold', borderWidth:3, backgroundColor:'black'}}/>
                </div>
            )
        })
    }

 

    let PageRedirect = null;

    return (
        <div>
            {PageRedirect}
            <Header />
            <div class="wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box">
                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Aninda Bi'Kurye</a></li>

                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Siparisler</a></li>
                                        <li class="breadcrumb-item active">Siparisler Listesi</li>
                                    </ol>
                                </div>
                                <h4 class="page-title">Siparisler Listesi</h4>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-box">
                                <div class="dropdown float-right">

                                </div>
                                <Link to='/' style={{ cursor: 'pointer' }} >
                                    <h4 class="mt-0 header-title"> <i class="ti-plus" style={{ color: 'green', fontSize: 18, fontWeight: 'bold', cursor: 'pointer' }}></i>Yeni Siparis Ver </h4>
                                </Link>
                                {/* <p class="text-muted font-14 mb-3" style={{ marginTop: 20 }}>
                                    <input className="form-control" value={userName} onChange={handleUserName} type="text" placeholder="Kurye Ara" />
                                  
                                </p> */}
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <thead>
                                            <tr>

                                                <th>Araç Tipi</th>
                                                <th>Odeme Tipi</th>
                                                <th>Odeme Yer</th>
                                                <th>Gonderi İçeri</th>
                                                <th>Fiyat</th>
                                                <th>Müşteri adı Soyadı</th>
                                                <th>Müşteri Numarası</th>
                                                <th>Durumu</th>
                                                <th>Kim Almiş</th>
                                                <th>Kurye Numarası</th>
                                                <th>Kime Verdi</th>
                                                <th>Zaman</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                loading ? <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th> <Loader /></th>
                                                    <th></th>
                                                </tr>
                                                    :
                                                    SiparisMap
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <Modal
                        isOpen={modal}
                        onRequestClose={handleCloseModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <h2>Addressler</h2>

                        {
                            
                              SiparisDetailss 

                          
                        }

                      
                       
                        
                      
                    </Modal>
                </div>
            </div>
            <Footer />
        </div>
    )
};
export default YeniSiparisList;