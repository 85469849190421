// IMPORT DEPENDENCIES & CONFIG
import Decode from 'jwt-decode';

// IMPORT URLS 
import {
    KURYE_LIST_URL, ADD_KURYE_URL, UPDATE_KURYE_URL, DELETE_KURYE_URL,
    GET_KURYE_CITY__URL, SEARCH_KURYE__URL, KURYE_PARASI_URL, KURYE_USER_INFOS_URL,
    SIFRE_SIFIRLA_URL, GET_APP_INFOS_URL, GET_USERS_URL, SEARCH_USER_URL, GET_SIPARIS_URL, GET_APP_SETTINGS_URL,
    UPDATE_APP_SETTINGS_URL, ADD_LOKANTA_URL, UPLOAD_FILE_URL, GET_LOKANTA_URL, SEARCH_LOKANTA_URL, UPDATE_LOKANTA_URL,
    DELETE_LOKANTA_URL, LOKANTA_USER_INFOS_URL, LOKANTA_ENGELLE_URL, LOKANTA_SIFIRLA_URL, ADD_CICEK_URL, GET_CICEK_URL, SEARCH_CICEK_URL,
    UPDATE_CICEK_URL, DELETE_CICEK_URL, CICEK_USER_INFOS_URL, CICEK_ENGELLE_URL, CICEK_SIFIRLA_URL, GET_SIPARIS_NEW_URL
} from '../../urls/admin/admin';

// IMPORT TYPES
import {
    GET_KURYE_START, GET_KURYE_SUCCESS, GET_KURYE_FAILED, ADD_KURYE_START, ADD_KURYE_SUCCESS, ADD_KURYE_FAILED,
    UPDATE_KURYE_START, UPDATE_KURYE_SUCCESS, UPDATE_KURYE_FAILED, UPDATE_KURYE_DATA, UPDATE_KURYE_DATA_CANCEL,
    DELETE_KURYE_DATA, DELETE_KURYE_DATA_CANCEL, DELETE_KURYE_START, DELETE_KURYE_SUCCESS, DELETE_KURYE_FAILED,
    GET_KURYE_CITY_START, GET_KURYE_CITY_SUCCESS, GET_KURYE_CITY_FAILED, SEARCH_KURYE_START, SEARCH_KURYE_SUCCESS, SEARCH_KURYE_FAILED,
    UPDATE_KURYE_PARASI, UPDATE_KURYE_PARASI_CANCEL, KURYE_PARASI_START, KURYE_PARASI_SUCCESS, KURYE_PARASI_FAILED,
    UPDATE_SIFRE_SIFIRLAMA, UPDATE_SIFRE_SIFIRLAMA_CANCEL, GET_KURYE_USER_INFO_START, GET_KURYE_USER_INFO_SUCCESS, GET_KURYE_USER_INFO_FAILED,
    SIFRE_SIFIRLA_START, SIFRE_SIFIRLA_SUCCESS, SIFRE_SIFIRLA_FAILED, GET_APP_INFO_START, GET_APP_INFO_SUCCESS, GET_APP_INFO_FAILED,
    GET_USER_START, GET_USER_SUCCESS, GET_USER_FAILED, SEARCH_USER_START, SEARCH_USER_SUCCESS, SEARCH_USER_FAILED, GET_SIPARIS_START,
    GET_SIPARIS_SUCCESS, GET_SIPARIS_FAILED, GET_APP_SETTINGS_START, GET_APP_SETTINGS_SUCCESS, GET_APP_SETTINGS_FAILED,
    HIZMET_BEDELI_AC, HIZMET_BEDELI_MK, KM_BASI_MK, KM_BASI_AC, MK_1_5, MK_6_11, MK_12_18, AC_1_5, AC_6_11, AC_12_18, LOKANTA_SWITCH, CICEK_SWITCH, YUZDE,
    API_KEY, UPDATE_APP_SETTINGS_START, UPDATE_APP_SETTINGS_SUCCESS, UPDATE_APP_SETTINGS_FAILED, DESTEK_NUMARA, ADD_LOKANTA_START, ADD_LOKANTA_SUCCESS, ADD_LOKANTA_FAILED,
    UPLOAD_FILE_START, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILED, GET_LOKANTA_START, GET_LOKANTA_SUCCESS, GET_LOKANTA_FAILED,
    SEARCH_LOKANTA_START, SEARCH_LOKANTA_SUCCESS, SEARCH_LOKANTA_FAILED, UPDATE_LOKANTA_DATA, UPDATE_LOKANTA_DATA_CANCEL,
    UPDATE_LOKANTA_START, UPDATE_LOKANTA_SUCCESS, UPDATE_LOKANTA_FAILED, DELETE_LOKANTA_DATA, DELETE_LOKANTA_DATA_CANCEL,
    DELETE_LOKANTA_START, DELETE_LOKANTA_SUCCESS, DELETE_LOKANTA_FAILED, GET_LOKANTA_USER_INFO_START, GET_LOKANTA_USER_INFO_SUCCESS, GET_LOKANTA_USER_INFO_FAILED,
    LOCKED_LOKANTA, LOKANTA_LOCKED_START, LOKANTA_LOCKED_SUCCESS, LOKANTA_LOCKED_FAILED, SIFIRLAMA_LOKANTA, LOKANTA_SIFIRLA_START, LOKANTA_SIFIRLA_SUCCESS, LOKANTA_SIFIRLA_FAILED,
    ADD_CICEK_START, ADD_CICEK_SUCCESS, ADD_CICEK_FAILED, GET_CICEK_START, GET_CICEK_SUCCESS, GET_CICEK_FAILED, SEARCH_CICEK_START, SEARCH_CICEK_SUCCESS, SEARCH_CICEK_FAILED,
    UPDATE_CICEK_DATA, UPDATE_CICEK_DATA_CANCEL, UPDATE_CICEK_START, UPDATE_CICEK_SUCCESS, UPDATE_CICEK_FAILED, DELETE_CICEK_DATA, DELETE_CICEK_DATA_CANCEL,
    DELETE_CICEK_START, DELETE_CICEK_SUCCESS, DELETE_CICEK_FAILED, GET_CICEK_USER_INFO_START, GET_CICEK_USER_INFO_SUCCESS, GET_CICEK_USER_INFO_FAILED,
    CICEK_LOCKED_START, CICEK_LOCKED_SUCCESS, CICEK_LOCKED_FAILED, CICEK_SIFIRLA_START, CICEK_SIFIRLA_SUCCESS, CICEK_SIFIRLA_FAILED, LOCKED_CICEK, SIFIRLAMA_CICEK, TOPLUDAGITIM,
    GET_SIPARIS_NEW_START, GET_SIPARIS_NEW_SUCCESS, GET_SIPARIS_NEW_FAILED, GET_SIPARIS_DETAIL, OPEN_MODAL, CLOSE_MODAL
} from '../../types/admin/admin';

export const getKuryeStart = () => {
    return {
        type: GET_KURYE_START
    }
}

export const getKuryeSuccess = (kuryelist) => {
    return {
        type: GET_KURYE_SUCCESS,
        kuryelist: kuryelist
    }
}

export const getKuryeFail = (error) => {
    return {
        type: GET_KURYE_FAILED,
        error: error
    }
}

export const getKuryeService = (token) => {
    return dispatch => {
        dispatch(getKuryeStart())
        fetch(KURYE_LIST_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getKuryeSuccess(response.data.response))
                }
                else {
                    dispatch(getKuryeFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(getKuryeFail(error));
            })
    }
}

export const addKuryeStart = () => {
    return {
        type: ADD_KURYE_START
    }
}

export const addKuryeSuccess = () => {
    return {
        type: ADD_KURYE_SUCCESS
    }
}

export const addKuryeFail = (error) => {
    return {
        type: ADD_KURYE_FAILED,
        error: error
    }
}

export const addKuryeService = (name, surname, cuzdan, email, tel, password, sehir, token) => {
    return dispatch => {
        dispatch(addKuryeStart())
        fetch(ADD_KURYE_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "name": name,
                "surname": surname,
                "cuzdan": cuzdan,
                "email": email,
                "sehir": sehir,
                "tel": tel,
                "password": password
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(addKuryeSuccess())
                }
                else {
                    dispatch(addKuryeFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(addKuryeFail(error));
            })
    }
}

export const updateKuryeData = (kuryeupdatedata) => {
    return {
        type: UPDATE_KURYE_DATA,
        kuryeupdatedata: kuryeupdatedata
    }
}

export const updateKuryeDataCancel = () => {
    return {
        type: UPDATE_KURYE_DATA_CANCEL
    }
}

export const updateKuryeStart = () => {
    return {
        type: UPDATE_KURYE_START
    }
}

export const updateKuryeSuccess = () => {
    return {
        type: UPDATE_KURYE_SUCCESS
    }
}

export const updateKuryeFail = (error) => {
    return {
        type: UPDATE_KURYE_FAILED,
        error: error
    }
}

export const updateKuryeService = (name, surname, cuzdan, tel, kuryeId, sehir, token) => {
    return dispatch => {
        dispatch(updateKuryeStart())
        fetch(UPDATE_KURYE_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "name": name,
                "surname": surname,
                "cuzdan": cuzdan,
                "kuryeId": kuryeId,
                "tel": tel,
                "sehir": sehir
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(updateKuryeSuccess())
                }
                else {
                    dispatch(updateKuryeFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(updateKuryeFail(error));
            })
    }
}

export const deleteKuryeData = (kuryedeletedata) => {
    return {
        type: DELETE_KURYE_DATA,
        kuryedeletedata: kuryedeletedata
    }
}

export const deleteKuryeDataCancel = () => {
    return {
        type: DELETE_KURYE_DATA_CANCEL
    }
}

export const deleteKuryeStart = () => {
    return {
        type: DELETE_KURYE_START
    }
}

export const deleteKuryeSuccess = () => {
    return {
        type: DELETE_KURYE_SUCCESS
    }
}

export const deleteKuryeFail = (error) => {
    return {
        type: DELETE_KURYE_FAILED,
        error: error
    }
}

export const deleteKuryeService = (kuryeId, token) => {
    return dispatch => {
        dispatch(deleteKuryeStart())
        fetch(DELETE_KURYE_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "kuryeId": kuryeId,
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(deleteKuryeSuccess())
                }
                else {
                    dispatch(deleteKuryeFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(deleteKuryeFail(error));
            })
    }
}

export const getKuryeCityStart = () => {
    return {
        type: GET_KURYE_CITY_START
    }
}

export const getKuryeCitySuccess = (kuryelist) => {
    return {
        type: GET_KURYE_CITY_SUCCESS,
        kuryelist: kuryelist
    }
}

export const getKuryeCityFail = (error) => {
    return {
        type: GET_KURYE_CITY_FAILED,
        error: error
    }
}

export const getKuryeCityService = (sehir, token) => {
    return dispatch => {
        dispatch(getKuryeCityStart())
        fetch(GET_KURYE_CITY__URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "sehir": sehir,
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getKuryeCitySuccess(response.data.response))
                }
                else {
                    dispatch(getKuryeCityFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(getKuryeCityFail(error));
            })
    }
}

export const searchKuryeStart = () => {
    return {
        type: SEARCH_KURYE_START
    }
}

export const searchKuryeSuccess = (kuryelist) => {
    return {
        type: SEARCH_KURYE_SUCCESS,
        kuryelist: kuryelist
    }
}

export const searchKuryeFail = (error) => {
    return {
        type: SEARCH_KURYE_FAILED,
        error: error
    }
}

export const searchKuryeService = (name, token) => {
    return dispatch => {
        dispatch(searchKuryeStart())
        fetch(SEARCH_KURYE__URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "name": name
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(searchKuryeSuccess(response.data.response))
                }
                else {
                    dispatch(searchKuryeFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(searchKuryeFail(error));
            })
    }
}

export const updateKuryeParasi = (kuryeparasidata) => {
    return {
        type: UPDATE_KURYE_PARASI,
        kuryeparasidata: kuryeparasidata
    }
}

export const updateKuryeParasiCancel = () => {
    return {
        type: UPDATE_KURYE_PARASI_CANCEL
    }
}

export const kuryeParasiStart = () => {
    return {
        type: KURYE_PARASI_START
    }
}

export const kuryeParasiSuccess = () => {
    return {
        type: KURYE_PARASI_SUCCESS,
    }
}

export const kuryeParasiFail = (error) => {
    return {
        type: KURYE_PARASI_FAILED,
        error: error
    }
}

export const kuryeParasiService = (parasi, kurieId, token) => {
    return dispatch => {
        dispatch(kuryeParasiStart())
        fetch(KURYE_PARASI_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "parasi": parasi,
                "kurieId": kurieId
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(kuryeParasiSuccess(response.data.response))
                }
                else {
                    dispatch(kuryeParasiFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(kuryeParasiFail(error));
            })
    }
}

export const updateSifreSifirlama = (sifresifirlamadata) => {
    return {
        type: UPDATE_SIFRE_SIFIRLAMA,
        sifresifirlamadata: sifresifirlamadata
    }
}

export const updateSifreSifirlamaCancel = () => {
    return {
        type: UPDATE_SIFRE_SIFIRLAMA_CANCEL
    }
}

export const getKuryeUserInfoStart = () => {
    return {
        type: GET_KURYE_USER_INFO_START
    }
}

export const getKuryeUserInfoSuccess = (kuryeuserinfo) => {
    return {
        type: GET_KURYE_USER_INFO_SUCCESS,
        kuryeuserinfo: kuryeuserinfo
    }
}

export const getKuryeUserInfoFail = (error) => {
    return {
        type: GET_KURYE_USER_INFO_FAILED,
        error: error
    }
}

export const getKuryeUserInfoService = (userId, token) => {
    return dispatch => {
        dispatch(getKuryeUserInfoStart())
        fetch(KURYE_USER_INFOS_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "userId": userId
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getKuryeUserInfoSuccess(response.data.response))
                }
                else {
                    dispatch(getKuryeUserInfoFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(getKuryeUserInfoFail(error));
            })
    }
}

export const sifreSifirlaStart = () => {
    return {
        type: SIFRE_SIFIRLA_START
    }
}

export const sifreSifirlaSuccess = () => {
    return {
        type: SIFRE_SIFIRLA_SUCCESS
    }
}

export const sifreSifirlaFail = (error) => {
    return {
        type: SIFRE_SIFIRLA_FAILED,
        error: error
    }
}

export const sifreSifirlaService = (userId, token) => {
    return dispatch => {
        dispatch(sifreSifirlaStart())
        fetch(SIFRE_SIFIRLA_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "userId": userId
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(sifreSifirlaSuccess())
                }
                else {
                    dispatch(sifreSifirlaFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(sifreSifirlaFail(error));
            })
    }
}

export const getAppInfoStart = () => {
    return {
        type: GET_APP_INFO_START
    }
}

export const getAppInfoSuccess = (userinfo, kuryeinfo, lokantainfo,cicekinfo) => {
    return {
        type: GET_APP_INFO_SUCCESS,
        userinfo: userinfo,
        kuryeinfo: kuryeinfo,
        lokantainfo:lokantainfo,
        cicekinfo:cicekinfo
    }
}

export const getAppInfoFail = (error) => {
    return {
        type: GET_APP_INFO_FAILED,
        error: error
    }
}

export const getAppInfoService = (token) => {
    return dispatch => {
        dispatch(getAppInfoStart())
        fetch(GET_APP_INFOS_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getAppInfoSuccess(response.data.user, response.data.kurye, response.data.lokanta, response.data.cicek ))
                }
                else {
                    dispatch(getAppInfoFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(getAppInfoFail(error));
            })
    }
}

export const getUserStart = () => {
    return {
        type: GET_USER_START
    }
}

export const getUserSuccess = (userlist) => {
    return {
        type: GET_USER_SUCCESS,
        userlist: userlist
    }
}

export const getUserFail = (error) => {
    return {
        type: GET_USER_FAILED,
        error: error
    }
}

export const getUserService = (token) => {
    return dispatch => {
        dispatch(getUserStart())
        fetch(GET_USERS_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getUserSuccess(response.data.response))
                }
                else {
                    dispatch(getUserFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(getUserFail(error));
            })
    }
}

export const searchUserStart = () => {
    return {
        type: SEARCH_USER_START
    }
}

export const searchUserSuccess = (userlist) => {
    return {
        type: SEARCH_USER_SUCCESS,
        userlist: userlist
    }
}

export const searchUserFail = (error) => {
    return {
        type: SEARCH_USER_FAILED,
        error: error
    }
}

export const searchUserService = (name, token) => {
    return dispatch => {
        dispatch(searchUserStart())
        fetch(SEARCH_USER_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "name": name
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(searchUserSuccess(response.data.response))
                }
                else {
                    dispatch(searchUserFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(searchUserFail(error));
            })
    }
}

export const getSiparisStart = () => {
    return {
        type: GET_SIPARIS_START
    }
}

export const getSiparisSuccess = (siparislist) => {
    return {
        type: GET_SIPARIS_SUCCESS,
        siparislist: siparislist
    }
}

export const getSiparisFail = (error) => {
    return {
        type: GET_SIPARIS_FAILED,
        error: error
    }
}

export const getSiparisService = (token) => {
    return dispatch => {
        dispatch(getSiparisStart())
        fetch(GET_SIPARIS_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getSiparisSuccess(response.data.response))
                }
                else {
                    dispatch(getSiparisFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(getSiparisFail(error));
            })
    }
}

export const getAppSettingsStart = () => {
    return {
        type: GET_APP_SETTINGS_START
    }
}

export const getAppSettingsSuccess = (appsettingslist, hizmetbedelimotorkurye, hizmetbedeliacilvehizli,
    kmbasimotorkurye, kmbasiacilveizli, motorkurye_1_5, motorkurye_6_11, motorkurye_12_18,
    acilvehizli_1_5, acilvehizli_6_11, acilvehizli_12_18, lokanta, cicek, apikey, number, yuzde, topludagitim) => {

    return {
        type: GET_APP_SETTINGS_SUCCESS,
        appsettingslist: appsettingslist,
        hizmetbedelimotorkurye: hizmetbedelimotorkurye,
        hizmetbedeliacilvehizli: hizmetbedeliacilvehizli,
        kmbasimotorkurye: kmbasimotorkurye,
        kmbasiacilveizli: kmbasiacilveizli,
        motorkurye_1_5: motorkurye_1_5,
        motorkurye_6_11: motorkurye_6_11,
        motorkurye_12_18: motorkurye_12_18,
        acilvehizli_1_5: acilvehizli_1_5,
        acilvehizli_6_11: acilvehizli_6_11,
        acilvehizli_12_18: acilvehizli_12_18,
        lokanta: lokanta,
        cicek: cicek,
        apikey: apikey,
        number: number,
        yuzde:yuzde,
        topludagitim:topludagitim
    }
}

export const getAppSettingsFail = (error) => {
    return {
        type: GET_APP_SETTINGS_FAILED,
        error: error
    }
}

export const getAppSettingsService = (token) => {
    return dispatch => {
        dispatch(getAppSettingsStart())
        fetch(GET_APP_SETTINGS_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getAppSettingsSuccess(response.data.response[0], response.data.response[0].hizmetbedelimotorkurye,
                        response.data.response[0].hizmetbedeliacilvehizli, response.data.response[0].kmbasimotorkurye,
                        response.data.response[0].kmbasiacilveizli, response.data.response[0].motorkurye_1_5,
                        response.data.response[0].motorkurye_6_11, response.data.response[0].motorkurye_12_18,
                        response.data.response[0].acilvehizli_1_5, response.data.response[0].acilvehizli_6_11,
                        response.data.response[0].acilvehizli_12_18, response.data.response[0].lokanta,
                        response.data.response[0].cicek, response.data.response[0].apikey, response.data.response[0].number,
                        response.data.response[0].yuzde, response.data.response[0].topludagitim
                    ))
                }
                else {
                    dispatch(getAppSettingsFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(getAppSettingsFail(error));
            })
    }
}

export const hizmetBedeliMK = (hizmetbedeliMK) => {
    return {
        type: HIZMET_BEDELI_MK,
        hizmetbedeliMK: hizmetbedeliMK
    }
}


export const topluDagitim = (topludagitim) => {
    return {
        type: TOPLUDAGITIM,
        topludagitim: topludagitim
    }
}


export const hizmetBedeliAC = (hizmetbedeliAC) => {
    return {
        type: HIZMET_BEDELI_AC,
        hizmetbedeliAC: hizmetbedeliAC
    }
}

export const kmBasiMK = (kmbasiMK) => {
    return {
        type: KM_BASI_MK,
        kmbasiMK: kmbasiMK
    }
}

export const kmBasiAC = (kmbasiAC) => {
    return {
        type: KM_BASI_AC,
        kmbasiAC: kmbasiAC
    }
}

export const mK_1_5 = (mk_1_5) => {
    return {
        type: MK_1_5,
        mk_1_5: mk_1_5
    }
}

export const mK_6_11 = (mk_6_11) => {
    return {
        type: MK_6_11,
        mk_6_11: mk_6_11
    }
}

export const mK_12_18 = (mk_12_18) => {
    return {
        type: MK_12_18,
        mk_12_18: mk_12_18
    }
}

export const aC_1_5 = (ac_1_5) => {
    return {
        type: AC_1_5,
        ac_1_5: ac_1_5
    }
}

export const aC_6_11 = (ac_6_11) => {
    return {
        type: AC_6_11,
        ac_6_11: ac_6_11
    }
}

export const aC_12_18 = (ac_12_18) => {
    return {
        type: AC_12_18,
        ac_12_18: ac_12_18
    }
}

export const lokantaSwitch = (lokanta) => {
    return {
        type: LOKANTA_SWITCH,
        lokanta: lokanta
    }
}

export const cicekSwitch = (cicek) => {
    return {
        type: CICEK_SWITCH,
        cicek: cicek
    }
}

export const ApiKey = (apikey) => {
    console.log("KJMD", apikey)
    return {
        type: API_KEY,
        apikey: apikey
    }
}

export const destekNumara = (desteknumara) => {
    return {
        type: DESTEK_NUMARA,
        desteknumara: desteknumara
    }
}

export const getYuzde = (yuzde) => {
    return {
        type: YUZDE,
        yuzde: yuzde
    }
}

export const updateAppSettingsStart = () => {
    return {
        type: UPDATE_APP_SETTINGS_START
    }
}

export const updateAppSettingsSuccess = () => {
    return {
        type: UPDATE_APP_SETTINGS_SUCCESS
    }
}

export const updateAppSettingsFail = (error) => {
    return {
        type: UPDATE_APP_SETTINGS_FAILED,
        error: error
    }
}

export const updateAppSettingsService = (hizmetbedelimotorkurye, kmbasimotorkurye, motorkurye_1_5, motorkurye_6_11, motorkurye_12_18,
    hizmetbedeliacilvehizli, kmbasiacilveizli, acilvehizli_1_5, acilvehizli_6_11, acilvehizli_12_18, apikey, lokanta, cicek, desteknumara, yuzde, topludagitim, token) => {
    return dispatch => {
        dispatch(updateAppSettingsStart())
        fetch(UPDATE_APP_SETTINGS_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "hizmetbedelimotorkurye": hizmetbedelimotorkurye,
                "kmbasimotorkurye": kmbasimotorkurye,
                "motorkurye_1_5": motorkurye_1_5,
                "motorkurye_6_11": motorkurye_6_11,
                "motorkurye_12_18": motorkurye_12_18,
                "hizmetbedeliacilvehizli": hizmetbedeliacilvehizli,
                "kmbasiacilveizli": kmbasiacilveizli,
                "acilvehizli_1_5": acilvehizli_1_5,
                "acilvehizli_6_11": acilvehizli_6_11,
                "acilvehizli_12_18": acilvehizli_12_18,
                "apikey": apikey,
                "lokanta": lokanta,
                "cicek": cicek,
                "number": desteknumara,
                "yuzde":yuzde,
                "topludagitim":topludagitim
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(updateAppSettingsSuccess())
                }
                else {
                    dispatch(updateAppSettingsFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(updateAppSettingsFail(error));
            })
    }
}

export const addLokantaStart = () => {
    return {
        type: ADD_LOKANTA_START
    }
}

export const addLokantaSuccess = () => {
    return {
        type: ADD_LOKANTA_SUCCESS
    }
}

export const addLokantaFail = (error) => {
    return {
        type: ADD_LOKANTA_FAILED,
        error: error
    }
}

export const addLokantaService = (name, sahibi, telephone, email, logo, password, token) => {
    return dispatch => {
        dispatch(addLokantaStart())
        fetch(ADD_LOKANTA_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "name": name,
                "sahibi": sahibi,
                "telephone": telephone,
                "email": email,
                "logo": logo,
                "password": password
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(addLokantaSuccess())
                }
                else {
                    dispatch(addLokantaFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(addLokantaFail(error));
            })
    }
}

export const uploadFileStart = () => {
    return {
        type: UPLOAD_FILE_START
    }
}

export const uploadFileSuccess = (logo) => {
    return {
        type: UPLOAD_FILE_SUCCESS,
        logo: logo
    }
}

export const uploadFileFail = (error) => {
    return {
        type: UPLOAD_FILE_FAILED,
        error: error
    }
}

export const uploadFileService = (file) => {
    const data = new FormData()
    data.append('file', file)
    console.log(data)
    return dispatch => {
        dispatch(uploadFileStart())
        fetch(UPLOAD_FILE_URL, {
            method: 'POST',
            // headers: {
            //     'content-type': file.type,
            //     'content-length': `${file.size}`, // 👈 Headers need to be a string
            //   },
            body: data
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    dispatch(uploadFileSuccess(response.filedestination))
                }
                else {
                    dispatch(uploadFileFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(uploadFileFail(error));
            })
    }
}

export const getLokantaStart = () => {
    return {
        type: GET_LOKANTA_START
    }
}

export const getLokantaSuccess = (lokantalist) => {
    return {
        type: GET_LOKANTA_SUCCESS,
        lokantalist: lokantalist
    }
}

export const getLokantaFail = (error) => {
    return {
        type: GET_LOKANTA_FAILED,
        error: error
    }
}

export const getLokantaService = (token) => {
    return dispatch => {
        dispatch(getLokantaStart())
        fetch(GET_LOKANTA_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getLokantaSuccess(response.data.response))
                }
                else {
                    dispatch(getLokantaFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(getLokantaFail(error));
            })
    }
}

export const searchLokantaStart = () => {
    return {
        type: SEARCH_LOKANTA_START
    }
}

export const searchLokantaSuccess = (lokantalist) => {
    return {
        type: SEARCH_LOKANTA_SUCCESS,
        lokantalist: lokantalist
    }
}

export const searchLokantaFail = (error) => {
    return {
        type: SEARCH_LOKANTA_FAILED,
        error: error
    }
}

export const searchLokantaService = (name, token) => {
    return dispatch => {
        dispatch(searchLokantaStart())
        fetch(SEARCH_LOKANTA_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "name": name
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(searchLokantaSuccess(response.data.response))
                }
                else {
                    dispatch(searchLokantaFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(searchLokantaFail(error));
            })
    }
}

export const updateLokantaData = (lokantadata) => {
    return {
        type: UPDATE_LOKANTA_DATA,
        lokantadata: lokantadata
    }
}

export const updateLokantaDataCancel = () => {
    return {
        type: UPDATE_LOKANTA_DATA_CANCEL
    }
}

export const updateLokantaStart = () => {
    return {
        type: UPDATE_LOKANTA_START
    }
}

export const updateLokantaSuccess = () => {
    return {
        type: UPDATE_LOKANTA_SUCCESS
    }
}

export const updateLokantaFail = (error) => {
    return {
        type: UPDATE_LOKANTA_FAILED,
        error: error
    }
}

export const updateLokantaService = (name, sahibi, telephone, lokantaId, token) => {
    return dispatch => {
        dispatch(updateLokantaStart())
        fetch(UPDATE_LOKANTA_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "name": name,
                "sahibi": sahibi,
                "telephone": telephone,
                "lokantaId": lokantaId
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(updateLokantaSuccess())
                }
                else {
                    dispatch(updateLokantaFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(updateLokantaFail(error));
            })
    }
}

export const deleteLokantaData = (lokantadata) => {
    return {
        type: DELETE_LOKANTA_DATA,
        lokantadata: lokantadata
    }
}

export const deleteLokantaDataCancel = () => {
    return {
        type: DELETE_LOKANTA_DATA_CANCEL
    }
}

export const deleteLokantaStart = () => {
    return {
        type: DELETE_LOKANTA_START
    }
}

export const deleteLokantaSuccess = () => {
    return {
        type: DELETE_LOKANTA_SUCCESS
    }
}

export const deleteLokantaFail = (error) => {
    return {
        type: DELETE_LOKANTA_FAILED,
        error: error
    }
}

export const deleteLokantaService = (lokantaId, userId, token) => {
    return dispatch => {
        dispatch(deleteLokantaStart())
        fetch(DELETE_LOKANTA_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "userId": userId,
                "lokantaId": lokantaId
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(deleteLokantaSuccess())
                }
                else {
                    dispatch(deleteLokantaFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(deleteLokantaFail(error));
            })
    }
}

export const getLokantaUserInfoStart = () => {
    return {
        type: GET_LOKANTA_USER_INFO_START
    }
}

export const getLokantaUserInfoSuccess = (lokantauserinfo) => {
    return {
        type: GET_LOKANTA_USER_INFO_SUCCESS,
        lokantauserinfo: lokantauserinfo
    }
}

export const getLokantaUserInfoFail = (error) => {
    return {
        type: GET_LOKANTA_USER_INFO_FAILED,
        error: error
    }
}

export const getLokantaUserInfoService = (userId, token) => {
    return dispatch => {
        dispatch(getLokantaUserInfoStart())
        fetch(LOKANTA_USER_INFOS_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "userId": userId
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getLokantaUserInfoSuccess(response.data.response))
                }
                else {
                    dispatch(getLokantaUserInfoFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(getLokantaUserInfoFail(error));
            })
    }
}

export const lockedLokanta = (lokantaloackeddata) => {
    return {
        type: LOCKED_LOKANTA,
        lokantaloackeddata: lokantaloackeddata
    }
}

export const lokantaLockedStart = () => {
    return {
        type: LOKANTA_LOCKED_START
    }
}

export const lokantaLockedSuccess = () => {
    return {
        type: LOKANTA_LOCKED_SUCCESS
    }
}

export const lokantaLockedFail = (error) => {
    return {
        type: LOKANTA_LOCKED_FAILED,
        error: error
    }
}

export const lokantaLockedService = (userId, locked, token) => {
    return dispatch => {
        dispatch(lokantaLockedStart())
        fetch(LOKANTA_ENGELLE_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "userId": userId,
                "locked": locked
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(lokantaLockedSuccess())
                }
                else {
                    dispatch(lokantaLockedFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(lokantaLockedFail(error));
            })
    }
}

export const sifirlamakLokanta = (lokantaloackeddata) => {
    return {
        type: SIFIRLAMA_LOKANTA,
        lokantaloackeddata: lokantaloackeddata
    }
}

export const lokantaSifirlaStart = () => {
    return {
        type: LOKANTA_SIFIRLA_START
    }
}

export const lokantaSifirlaSuccess = () => {
    return {
        type: LOKANTA_SIFIRLA_SUCCESS
    }
}

export const lokantaSifirlaFail = (error) => {
    return {
        type: LOKANTA_SIFIRLA_FAILED,
        error: error
    }
}

export const lokantaSifirlaService = (userId, token) => {
    return dispatch => {
        dispatch(lokantaSifirlaStart())
        fetch(LOKANTA_SIFIRLA_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "userId": userId
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(lokantaSifirlaSuccess())
                }
                else {
                    dispatch(lokantaSifirlaFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(lokantaSifirlaFail(error));
            })
    }
}

export const addCicekStart = () => {
    return {
        type: ADD_CICEK_START
    }
}

export const addCicekSuccess = () => {
    return {
        type: ADD_CICEK_SUCCESS
    }
}

export const addCicekFail = (error) => {
    return {
        type: ADD_CICEK_FAILED,
        error: error
    }
}

export const addCicekService = (name, sahibi, telephone, email, logo, password, token) => {
    return dispatch => {
        dispatch(addCicekStart())
        fetch(ADD_CICEK_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "name": name,
                "sahibi": sahibi,
                "telephone": telephone,
                "email": email,
                "logo": logo,
                "password": password
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(addCicekSuccess())
                }
                else {
                    dispatch(addCicekFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(addCicekFail(error));
            })
    }
}

export const getCicekStart = () => {
    return {
        type: GET_CICEK_START
    }
}

export const getCicekSuccess = (ciceklist) => {
    return {
        type: GET_CICEK_SUCCESS,
        ciceklist: ciceklist
    }
}

export const getCicekFail = (error) => {
    return {
        type: GET_CICEK_FAILED,
        error: error
    }
}

export const getCicekService = (token) => {
    return dispatch => {
        dispatch(getCicekStart())
        fetch(GET_CICEK_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getCicekSuccess(response.data.response))
                }
                else {
                    dispatch(getCicekFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(getCicekFail(error));
            })
    }
}

export const searchCicekStart = () => {
    return {
        type: SEARCH_CICEK_START
    }
}

export const searchCicekSuccess = (ciceklist) => {
    return {
        type: SEARCH_CICEK_SUCCESS,
        ciceklist: ciceklist
    }
}

export const searchCicekFail = (error) => {
    return {
        type: SEARCH_CICEK_FAILED,
        error: error
    }
}

export const searchCicekService = (name, token) => {
    return dispatch => {
        dispatch(searchCicekStart())
        fetch(SEARCH_CICEK_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "name": name
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(searchCicekSuccess(response.data.response))
                }
                else {
                    dispatch(searchCicekFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(searchCicekFail(error));
            })
    }
}

export const updateCicekData = (cicekdata) => {
    return {
        type: UPDATE_CICEK_DATA,
        cicekdata: cicekdata
    }
}

export const updateCicekDataCancel = () => {
    return {
        type: UPDATE_CICEK_DATA_CANCEL
    }
}

export const updateCicekStart = () => {
    return {
        type: UPDATE_CICEK_START
    }
}

export const updateCicekSuccess = () => {
    return {
        type: UPDATE_CICEK_SUCCESS
    }
}

export const updateCicekFail = (error) => {
    return {
        type: UPDATE_CICEK_FAILED,
        error: error
    }
}

export const updateCicekService = (name, sahibi, telephone, cicekId, token) => {
    return dispatch => {
        dispatch(updateCicekStart())
        fetch(UPDATE_CICEK_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "name": name,
                "sahibi": sahibi,
                "telephone": telephone,
                "cicekId": cicekId
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(updateCicekSuccess())
                }
                else {
                    dispatch(updateCicekFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(updateCicekFail(error));
            })
    }
}

export const deleteCicekData = (cicekdata) => {
    return {
        type: DELETE_CICEK_DATA,
        cicekdata: cicekdata
    }
}

export const deleteCicekDataCancel = () => {
    return {
        type: DELETE_CICEK_DATA_CANCEL
    }
}

export const deleteCicekStart = () => {
    return {
        type: DELETE_CICEK_START
    }
}

export const deleteCicekSuccess = () => {
    return {
        type: DELETE_CICEK_SUCCESS
    }
}

export const deleteCicekFail = (error) => {
    return {
        type: DELETE_CICEK_FAILED,
        error: error
    }
}

export const deleteCicekService = (cicekId, userId, token) => {
    return dispatch => {
        dispatch(deleteCicekStart())
        fetch(DELETE_CICEK_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "userId": userId,
                "cicekId": cicekId
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(deleteCicekSuccess())
                }
                else {
                    dispatch(deleteCicekFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(deleteCicekFail(error));
            })
    }
}

export const getCicekUserInfoStart = () => {
    return {
        type: GET_CICEK_USER_INFO_START
    }
}

export const getCicekUserInfoSuccess = (cicekuserinfo) => {
    return {
        type: GET_CICEK_USER_INFO_SUCCESS,
        cicekuserinfo: cicekuserinfo
    }
}

export const getCicekUserInfoFail = (error) => {
    return {
        type: GET_CICEK_USER_INFO_FAILED,
        error: error
    }
}

export const getCicekUserInfoService = (userId, token) => {
    return dispatch => {
        dispatch(getCicekUserInfoStart())
        fetch(CICEK_USER_INFOS_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "userId": userId
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getCicekUserInfoSuccess(response.data.response))
                }
                else {
                    dispatch(getCicekUserInfoFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(getCicekUserInfoFail(error));
            })
    }
}

export const lockedCicek = (cicekloackeddata) => {
    return {
        type: LOCKED_CICEK,
        cicekloackeddata: cicekloackeddata
    }
}

export const cicekLockedStart = () => {
    return {
        type: CICEK_LOCKED_START
    }
}

export const cicekLockedSuccess = () => {
    return {
        type: CICEK_LOCKED_SUCCESS
    }
}

export const cicekLockedFail = (error) => {
    return {
        type: CICEK_LOCKED_FAILED,
        error: error
    }
}

export const cicekLockedService = (userId, locked, token) => {
    return dispatch => {
        dispatch(cicekLockedStart())
        fetch(CICEK_ENGELLE_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "userId": userId,
                "locked": locked
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(cicekLockedSuccess())
                }
                else {
                    dispatch(cicekLockedFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(cicekLockedFail(error));
            })
    }
}

export const sifirlamakcicek = (cicekloackeddata) => {
    return {
        type: SIFIRLAMA_CICEK,
        cicekloackeddata: cicekloackeddata
    }
}

export const cicekSifirlaStart = () => {
    return {
        type: CICEK_SIFIRLA_START
    }
}

export const cicekSifirlaSuccess = () => {
    return {
        type: CICEK_SIFIRLA_SUCCESS
    }
}

export const cicekSifirlaFail = (error) => {
    return {
        type: CICEK_SIFIRLA_FAILED,
        error: error
    }
}

export const cicekSifirlaService = (userId, token) => {
    return dispatch => {
        dispatch(cicekSifirlaStart())
        fetch(CICEK_SIFIRLA_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify({
                "userId": userId
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(cicekSifirlaSuccess())
                }
                else {
                    dispatch(lokantaSifirlaFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(cicekSifirlaFail(error));
            })
    }
}

export const getSiparisNewStart = () => {
    return {
        type: GET_SIPARIS_NEW_START
    }
}

export const getSiparisNewSuccess = (newsiparislist) => {
    return {
        type: GET_SIPARIS_NEW_SUCCESS,
        newsiparislist: newsiparislist
    }
}

export const getSiparisNewFail = (error) => {
    return {
        type: GET_SIPARIS_NEW_FAILED,
        error: error
    }
}

export const getSiparisNewService = (token) => {
    return dispatch => {
        dispatch(getSiparisNewStart())
        fetch(GET_SIPARIS_NEW_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getSiparisNewSuccess(response.data.response))
                }
                else {
                    dispatch(getSiparisNewFail("Error"));
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(getSiparisNewFail(error));
            })
    }
}

export const getSiparisDetail = (siparisdetail) => {
    return {
        type: GET_SIPARIS_DETAIL,
        siparisdetail:siparisdetail
    }
}

export const openModal= () => {
    return {
        type: OPEN_MODAL
    }
}

export const closeModal= () => {
    return {
        type: CLOSE_MODAL
    }
}
