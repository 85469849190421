
import React, { useEffect } from 'react'

//IMPORT CONFIG & DEPENDENCIES
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginCheckState } from './actions/auth/auth';



// IMPORT PAGE
import Login from './pages/login/login';
import Home from './pages/home/home';
import KuryeListesi from './pages/kuryelist/kuryelist';
import AddKurye from './pages/addkurye/addkurye';
import UpdateKurye from './pages/updatekurye/updatekurye';
import DeleteKurye from './pages/deletekurye/deletekurye';
import KuryeParasi from './pages/kuryepara/kuryepara';
import KuryeSifreSifirlama from './pages/kuryesifresifirlama/kuryesifresifirlama';
import UserList from './pages/userlist/userlist';
import SiparisList from './pages/siparislist/siparislist';
import Settings from './pages/settings/settings';
import Yenilokanta from './pages/yenilokanta/yenilokanta';
import LokantaList from './pages/lokantalist/lokantalist';
import UpdateLokanta from "./pages/updatelokanta/updatelokanta";
import DeleteLokanta from './pages/deletelokanta/deletelokanta';
import EngelleLokanta from './pages/locklokantaccount/locklokantaaccount';
import LokantaSifirlamak from './pages/lokantasifirlamak/lokantasifirlamak';
import YeniCicek from './pages/yenicicek/yenicicek';
import CicekList from './pages/ciceklist/ciceklist';
import UpdateCicek from './pages/updatecicek/updatecicek';
import DeleteCicek from './pages/deletecicek/deletecicek';
import LockCicekAccount from './pages/lockcicekaccount/lockcicekaccount';
import CicekSifirlamak from './pages/ciceksifirlamak/ciceksifirlamak';
import NewSiparisList from './pages/siparislist/yenisiparislist';

const App = () => {

  const dispatch = useDispatch();

  const token = useSelector(state => state.AuthReducer.token);
  const role = useSelector(state => state.AuthReducer.role);

  useEffect(() => {
    dispatch(loginCheckState());
  }, []);

  let route = (
    <Switch>
      {/* <Route path="/home" component={Home} /> */}
      <Route path="/login" component={Login} />
      <Redirect to='/login' />
    </Switch>
  )

  if (token && (role === 'admin')) {
    route = (
      <Switch>
        <Route path="/newsiparislist" component={NewSiparisList} />
        <Route path="/cicek-sifre-sifirlama" component={CicekSifirlamak} />
        <Route path="/cicek-engellemek" component={LockCicekAccount} />
        <Route path="/cicek-sil" component={DeleteCicek} />
        <Route path="/cicek-Duzenle" component={UpdateCicek} />
        <Route path="/cicek-list" component={CicekList} />
        <Route path="/yenicicek" component={YeniCicek} />
        <Route path="/lokanta-sifre-sifirlama" component={LokantaSifirlamak} />
        <Route path="/lokanta-engellemek" component={EngelleLokanta} />
        <Route path="/lokanta-sil" component={DeleteLokanta} />
        <Route path="/lokanta-Duzenle" component={UpdateLokanta} />
        <Route path="/lokanta-list" component={LokantaList} />
        <Route path="/yenilokanta" component={Yenilokanta} />
        <Route path="/settings" component={Settings} />
        <Route path="/siparis-listesi" component={SiparisList} />
        <Route path="/user-list" component={UserList} />
        <Route path="/kurye-sifre-sifirlama" component={KuryeSifreSifirlama} />
        <Route path="/kurye-parasi" component={KuryeParasi} />
        <Route path="/kurye-Sil" component={DeleteKurye} />
        <Route path="/kurye-Duzenle" component={UpdateKurye} />
        <Route path="/yeni-kurye-kaydet" component={AddKurye} />
        <Route path="/kurye-listesi" component={KuryeListesi} />
        <Route path="/" exact component={Home} />
        <Redirect to='/' />
      </Switch>
    )
  }

  return (
    <div>
      {route}
    </div>
  );
}

export default withRouter(App);
