import React, { useEffect, useState } from 'react';
import Decode from 'jwt-decode';

// IMPORT DEPENDENCIES
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCicekUserInfoService, cicekSifirlaService } from '../../actions/admin/admin';

//IMPORT COMPONENT
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Loader from '../../components/loader/loader';

const CicekSifirlamak = () => {

    const Dispatch = useDispatch();



    const token = useSelector(state => state.AuthReducer.token);
    const ciceksirlamaktatus = useSelector(state => state.AdminReducer.ciceksirlamaktatus);
    const loading = useSelector(state => state.AdminReducer.loading);
    const loadingsifir = useSelector(state => state.AdminReducer.loadingsifir);
    const cicekuserinfo = useSelector(state => state.AdminReducer.cicekuserinfo);
    const cicekloackeddata = useSelector(state => state.AdminReducer.cicekloackeddata)

    var decoded = Decode(token)

    useEffect(() => {
        Dispatch(getCicekUserInfoService(cicekloackeddata.userId, token));
    }, []);


    const [error, setError] = useState(false);


    const handleSifirlama = (e) => {
        e.preventDefault();
        Dispatch(cicekSifirlaService(cicekloackeddata.userId, token))
    }

    let PageRedirect = null
    if (ciceksirlamaktatus) {
        PageRedirect = <Redirect to="/cicek-list" />
    }

    return (
        <div>
            <Header />
            <div class="wrapper">
                <div class="container-fluid">
                    {PageRedirect}
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box">
                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Aninda Bi'Kurye</a></li>
                                        <li class="breadcrumb-item"><a href="javascript: void(0);"> Cicek Sifre</a></li>
                                        <li class="breadcrumb-item active"> Sifirlama</li>
                                    </ol>
                                </div>
                                <h4 class="page-title"></h4>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        {
                            loadingsifir ?
                                <div class="col-sm-10">
                                    <Loader />
                                </div>
                                :
                                <>
                                    <div class="col-12">
                                        <div class="card-box">
                                            <h4 class="m-t-0 header-title"> Cicek Sifre Sifirlama</h4>
                                            <p class="text-muted mb-4 font-14">
                                            </p>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="p-2">
                                                        <form class="form-horizontal" role="form">
                                                            <div class="form-group row">
                                                                <label class="col-sm-2  col-form-label" for="simpleinput">Email</label>
                                                                <div class="col-sm-10">
                                                                    <input type="text" value={cicekuserinfo.email} id="simpleinput" disabled class="form-control" placeholder='email' />
                                                                    <p style={{color:'red', marginTop:10}}>Şifrenin sıfırlanması aninda olacaktır.</p>
                                                                </div>
                                                              
                                                            </div>

                                                            {
                                                                error ?
                                                                    <div class="form-group row">
                                                                        <label class="col-sm-2  col-form-label" for="simpleinput"></label>
                                                                        <div class="col-sm-10">
                                                                            <p style={{ color: 'red' }}>Bilgilerinizi kontrol edin lütfen!</p>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                            <div class="form-group row">
                                                                <label class="col-sm-2  col-form-label" for="simpleinput"></label>
                                                                {loading ? <div class="col-sm-10">
                                                                    <Loader />
                                                                </div>
                                                                    :
                                                                    <div class="col-sm-10">
                                                                        <button onClick={handleSifirlama} class="btn btn-success">Sifirla Yap</button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>

                        }

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default CicekSifirlamak