import React, { useEffect, useState } from 'react';
import Decode from 'jwt-decode';

// IMPORT DEPENDENCIES
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserService, searchUserService } from '../../actions/admin/admin';
import Moment from 'react-moment';
import 'moment/locale/tr';

//IMPORT COMPONENT
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Loader from '../../components/loader/loader';

const UserList = (props) => {

    const Dispatch = useDispatch();
    const token = useSelector(state => state.AuthReducer.token);
    const loading = useSelector(state => state.AdminReducer.loading);
    const userlist = useSelector(state => state.AdminReducer.userlist);
    

    var decoded = Decode(token)

    const [userName, setUserName] = useState('')

    useEffect(() => {
            Dispatch(getUserService(token));
    }, []);


    const handleUserName = (e) => {
        setUserName(e.target.value)
        Dispatch(searchUserService(e.target.value, token))
    }
   
   
    let UserMap = userlist.map((item, index) => {
        return (
            <tr key={index}>

                <td>{item.name}</td>
                <td>{item.surname}</td>
                <td>{item.telephone}</td>
                <td> <Moment fromNow>{item.createdAt}</Moment> </td>
                {/* <td>
                    <i onClick={() => updateKurye(item)} class="ti-pencil" style={{ color: 'green', fontSize: 18, fontWeight: 'bold', cursor: 'pointer' }}></i>
                    <i onClick={() => deleteKurye(item)} class="ti-trash" style={{ color: 'red', fontSize: 18, marginLeft: 15, cursor: 'pointer' }}></i>
                    <i onClick={() => kuryeParasi(item)} class="ti-money" style={{ color: 'blue', fontSize: 18, marginLeft: 15, cursor: 'pointer' }}></i>
                    <i onClick={() => sifirlamaSifre(item)} class="ti-user" style={{ color: 'orange', fontSize: 18, marginLeft: 15, cursor: 'pointer' }}></i>
                </td> */}
            </tr>
        )
    })

    let PageRedirect = null;

    return (
        <div>
            {PageRedirect}
            <Header />
            <div class="wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box">
                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Aninda Bi'Kurye</a></li>

                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Kullanicilar</a></li>
                                        <li class="breadcrumb-item active">Kullanicilar Listesi</li>
                                    </ol>
                                </div>
                                <h4 class="page-title">Kullanicilar Listesi</h4>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-box">
                                <div class="dropdown float-right">

                                </div>
                                <Link to='/' style={{ cursor: 'pointer' }} >
                                    <h4 class="mt-0 header-title"> <i class="ti-plus" style={{ color: 'green', fontSize: 18, fontWeight: 'bold', cursor: 'pointer' }}></i>Yeni Kullanici Kaydet </h4>
                                </Link>
                                <p class="text-muted font-14 mb-3" style={{ marginTop: 20 }}>
                                    <input className="form-control" value={userName} onChange={handleUserName} type="text" placeholder="Kullanici Ara" />
                                  
                                </p>
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <thead>
                                            <tr>

                                                <th>Adi</th>
                                                <th>Soyadi</th>
                                                <th>Telefon</th>
                                                <th>Kayit</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                loading ? <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th> <Loader /></th>
                                                    <th></th>
                                                </tr>
                                                    :
                                                    UserMap
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};
export default UserList;